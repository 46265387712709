export const partnersConfig = [
    {
        name: 'Atlantic Records',
        url: 'http://www.atlanticrecords.com/',
    },
    {
        name: 'AGBU',
        url: 'https://agbu.org/',
    },
    {
        name: 'Schusterman',
        url: 'https://www.schusterman.org/jewish-community-and-israel/signature-initiatives/reality-experience',
    },
    {
        name: 'Live Nation Israel',
        url: 'https://www.livenation.co.il/',
    },
    {
        name: 'Interscope',
        url: 'https://www.interscope.com/',
    },
    {
        name: 'Warner Brothers Records',
        url: 'http://www.warnerbrosrecords.com/',
    },
    {
        name: 'Warner Chappell',
        url: 'http://www.warnerchappell.com',
    },
    {
        name: 'Reverb',
        url: 'https://reverb.com',
    },
    {
        name: 'Raising Malawi',
        url: 'http://www.raisingmalawi.org',
    },
    {
        name: 'United States Embassy',
        url: 'https://www.usembassy.gov',
    },
    {
        name: 'Gibson Foundation',
        url: 'https://www.gibsonfoundation.org',
    },
    {
        name: 'Republic Records',
        url: 'http://www.republicrecords.com',
    },
    {
        name: 'Def Jam',
        url: 'https://www.defjam.com',
    },
    {
        name: 'Sony Music Group',
        url: 'https://www.sonymusic.com/',
    },
    {
        name: 'Jason Mraz Foundation',
        url: 'https://jasonmraz.com/foundation/',
    },
    {
        name: 'Shure',
        url: 'https://www.shure.com/',
    },
    {
        name: 'Hosa',
        url: 'https://hosatech.com/',
    },
    {
        name: 'Focusrite',
        url: 'https://focusrite.com/',
    },
    {
        name: 'Best Buy Foundation',
        url: 'https://www.bestbuycharityclassic.com/',
    },
    {
        name: 'Hirair and Anna Hovnanian Foundation',
        url: 'https://www.hovnanianfoundation.am',
    },
    {
        name: 'Rex Foundation',
        url: 'https://www.rexfoundation.org',
    },
    {
        name: 'Soundwaves Art Foundation',
        url: 'https://soundwavesartfoundation.com',
    },
    {
        name: 'Novation',
        url: 'https://novationmusic.com',
    },
    {
        name: 'SheArts',
        url: '',
    },
];
