import PropTypes from 'prop-types';

const Translate = props => {
    const {
        children,
    } = props;

    return (
        children
    )
};

const t = text => {
    return text;
};

Translate.propTypes = {
    children: PropTypes.any.isRequired,
};

export {
    Translate,
    t,
};
