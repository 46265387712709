import React from 'react';

import { Translate } from '../../helpers/translate';

import tamar from './media/tamar.jpg';
import kim from './media/kim.jpg';
import mariam from './media/mariam.jpg';
import hannah from './media/hannah.jpg';
import yasmin from './media/yasmin.jpeg';
import hayley from './media/hayley.jpeg';
import nikisha from './media/nikisha.jpeg';
import tarek from './media/tarek.jpeg';

export const teamConfig = [
    {
        name: <Translate>Tamar Kaprelian</Translate>,
        position: <Translate>Founder, CEO</Translate>,
        image: tamar,
        text: <Translate>Tamar ​is an Armenian-American singer/songwriter living in New York City. Her debut album, ​Sinner or a Saint​, was released on Interscope records in 2010, and in 2015 she represented Armenia in the Eurovision Song Contest. Tamar graduated Summa Cum Laude and Phi Beta Kappa with a degree in English and Comparative Literature from Columbia University in New York.</Translate>
    },
    {
        name: <Translate>Hannah Hyman</Translate>,
        position: <Translate>Foundation Manager</Translate>,
        image: hannah,
        text: <Translate>Hannah Hyman is a Cum Laude graduate of Louisiana State University from Baton Rouge, Louisiana. She's passionate about music and its important role in youth development. While in college, she worked in radio and as a drum instructor for Girls Rock Camp. After graduating from the E.J. Ourso College of Business, she moved to Pamplona, Spain to teach English in a primary school. She has been a drummer since she was 13, playing in bands around Louisiana for nearly 10 years. As the newest member of the Nvak Foundation team, Hannah brings a unique background of music, education, and business to her role.</Translate>,
    },
    {
        name: <Translate>Mariam Pirijanian</Translate>,
        position: <Translate>Operations Manager - Armenia</Translate>,
        image: mariam,
        text: <Translate>Music has always been an integral part of Mariam’s life. When she was just 7 years old, Mariam attended music school. There, she learned to sing, play the piano, and the guitar, and dreamt of becoming a rockstar. At the age of 20 she started a rock band with her friends, which collapsed soon after due to lack of support for musicians. Having experienced these challenges, Mariam has always been interested in understanding the ways in which the global music industry works, how music is composed and created, and ways to improve the music experience for those around her. She began working at TUMO Center for Creative Technologies in education for youth, and began assisting Nvak in 2017. Mariam fell in love with the concept of teaching students how to create and produce songs, and how to become real artists. In 2021, Mariam is excited to be working as the Operations Manager for Armenia, as part of the Nvak team and the connected global community.</Translate>,
    },
    {
        name: <Translate>Nikisha Bailey</Translate>,
        position: <Translate>General Manager</Translate>,
        image: nikisha,
        text: (
            <Translate>
                As General Manager for Nvak, Nikisha Bailey provides an invaluable creative perspective to the facilitation of A&R, Marketing, and Label Operations for the company’s diverse and highly innovative roster of creatives.
                <br />
                <br />
                Extending her influence throughout music and culture, she also serves as Vice President for The Recording Academy’s New York Chapter and is an Ambassador for the Black Music Collective (BMC). Nikisha owns one of Philadelphia's renowned coffee brands and community space; Win Win Coffee Bar and serves as Co Chair on Women in Music's Diversity and Inclusion Council.
                <br />
                <br />
                No matter what she does, the executive, entrepreneur, people manager, and philanthropist builds on her lifelong commitment to music, first and foremost. Nikisha was recently a recipient of Urban SkinRX’s Community Changemaker Award, listed on Forbes Next 1000 list and slated as a Billboard Power Player in 2020 and and 2021.
                <br />
                <br />
                “I try to understand artists on a musical level before anything else,” she says. “That’s how you build lasting trust—by showing a real love and appreciation for music, because it brings people together. I want that love for music to be my signature in everything I’m working on.” The St. Louis native went from playing bass and tuba throughout high school to achieving a Bachelor of Arts in Audio Engineering from Webster University. She kicked off her career as an intern at Atlantic before eventually becoming an A&R Admin Assistant in 2011. She became A&R Admin Manager for Def Jam Recordings during 2015 and worked on chart-topping multi platinum releases for the likes of YG, Jhene Aiko and Lil Durk, acting as a label liaison for producers, studios, engineers, and musicians. 2018 saw her return to Atlantic in the role of Head of A&R Operations and Production, Artist Partners, where she worked on key records for talent such as Kehlani, Lil Skies, Youngboy and many others. In 2022, Nikisha joined groundbreaking record label and artist advocacy group, Nvak Collective as General Manage with a mission to rebuild the music industry from the ground up by restructuring how IP is financed, monetized, created, and experienced. Using blockchain technology and immersive virtual experiences Nvak Collective forms a direct-to-fan” model selling “music experiences'' to build fan base: packages of music singles, content, live events, and NFT’s directly to artist communities
            </Translate>
        ),
    },
    {
        name: 'Kimberley Kaunda',
        position: 'A&R Africa Region + Community Manager',
        image: kim,
        text: <Translate>Kimberley Kaunda is a dynamic Malawian singer-songwriter, community manager, and A&R consultant for Nvak. With a passion for fostering a thriving community of emerging artists in her country, she is passionate about artist development, music business & music education. Through virtual and in-person music education workshops and songwriting camps, Kimberley empowers aspiring musicians to build sustainable careers and connect with top opportunities across Africa and beyond. Her areas of expertise include artist development, studio recording, songwriting, leadership, workshop facilitation, A&R, social media management, and community building.</Translate>,
    },
    {
        name: 'Tarek Nader',
        position: 'Community Manager, Egypt',
        image: tarek,
        text: <Translate>Tarek is an independent writer, translator, and theater director. He has been working in arts and cultural management for years, and he has coordinated many cultural projects and festivals in Alexandria and Cairo. He’s also a certified trainer in the field of creative entrepreneurship. Tarek joined Nvak recently as the Community Manager for the MuSHEqa project and is eager to help facilitate this project for local aspiring artists.</Translate>
    },
    {
        name: <Translate>Hayley Malesich</Translate>,
        position: <Translate>Digital Marketer</Translate>,
        image: hayley,
        text: <Translate>Hayley is a music industry professional from Philadelphia, Pennsylvania and is currently based in Salt Lake City, Utah. She has earned a Bachelor of Science in Music Industry and Business from Rowan University. Hayley has worked in various areas of the music industry including live music, artist services, social media and concert promotion. When she isn’t working, you can find her reading, going to concerts, or spending time with her husband, Jeff, and dog, Charlie.</Translate>,
    },
    {
        name: <Translate>Yasmin Reis</Translate>,
        position: <Translate>Intern</Translate>,
        image: yasmin,
        text: <></>,
    },
];
