export const advisersConfig = [
    {
        name: 'Alex Patsavas',
        url: 'http://chopshopmusic.com',
        company: 'Chop Shop',
    },
    {
        name: 'Craig Kallman',
        url: 'http://www.atlanticrecords.com',
        company: 'Atlantic Records',
    },
    {
        name: 'Jason Bernard',
        url: 'http://pulserecordings.com',
        company: 'Pulse Recordings',
    },
    {
        name: 'Joanna Bomberg',
        url: 'https://www.refinery29.com/',
        company: 'Sr. Talent Relations Executive',
    },
    {
        name: 'Rochelle Holguin',
        url: 'http://www.viacom.com/brands/pages/mtv.aspx',
        company: 'Viacom',
    },
    {
        name: 'Chris Stang',
        url: 'https://www.theinfatuation.com/about',
        company: 'The Infatuation',
    },
    {
        name: 'Sam Riback',
        url: 'https://www.interscope.com',
        company: 'Interscope',
    },
    {
        name: 'Cook Classics',
        url: '#',
        company: 'Producer and Songwriter',
    },
    {
        name: 'Nikisha Bailey',
        url: 'http://artistpg.com/',
        company: 'VP of A&R Admin, APG',
    },
    {
        name: 'Raeann Vinton',
        url: 'https://www.gibson.com',
        company: 'Entertainment Relations, Gibson',
    },
    {
        name: 'Tony Everett',
        url: 'https://www.linkedin.com/in/tonyeverett/',
        company: 'Marketing Specialist',
    },
];
