import React from 'react';

import { Translate } from '../../helpers/translate';

import shearts from './media/shearts.jpg';
import shearts2x from './media/shearts@2x.png';
import usCairo from './media/usCairo.jpg';
import usCairo2x from './media/usCairo@2x.png';

export const cityContent = {
    yerevan: [
        () => <Translate>Nvak Foundation began as a summer intensive music program for youth in Yerevan, Armenia. Read more about our work in the region below!</Translate>,
        {
            type: 'iframe',
            url: 'https://www.youtube.com/embed/M0bwSiFiik4',
        },
        {
            type: 'h3',
            text: () => <Translate>Why Yerevan?</Translate>,
        },
        () => <Translate>Musicians in Armenia face unique challenges. With a national population of less than 3 million people, access to outside opportunities and exposure is crucial for the creative community.</Translate>,
        () => <Translate>In spite of this, Armenia has a thriving underground of young, passionate, and extraordinarily talented young musicians and writers who are eager to make their voices heard.</Translate>,
        {
            type: 'iframe',
            url: 'https://www.youtube.com/embed/5SKURTN46v0',
        },
        {
            type: 'h3',
            text: () => <Translate>Nvak Yerevan's Impact</Translate>,
        },
        () => <Translate>In just five years, Nvak has developed a community of 1000+ active and engaged singers, songwriters, and producers from throughout Armenia</Translate>,
        {
            type: 'ul',
            items: [
                () => (
                    <>
                        <strong>
                            <Translate>Local Exposure:</Translate>
                        </strong>
                        {` `}
                        <Translate>Each week, our staff and interns organized social events and live performances (in the form of open-mic nights, busking, songwriter circles) in and around Yerevan.</Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>Regional Exposure:</Translate>
                        </strong>
                        {` `}
                        <Translate>​In February of 2018, two of the songs written by five of Nvak’ssongwriters were featured on Depi Evradesil, Armenia’s qualifying show for Eurovision.</Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>Global Exposure:</Translate>
                        </strong>
                        {` `}
                        <Translate>Partnership with ADA to distribute, market, and license 2019 album with contributions from all Nvak markets.</Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>Industry Collaborations:</Translate>
                        </strong>
                        {` `}
                        <Translate>Collaborations with Alex Salibian (Producer for Harry Styles); Larzz Principato (Producer for Dua Lipa); The Kinetics; Max Hershenow (Producer for MsMr); and Gladius (Producer for Justin Bieber).</Translate>
                    </>
                ),
            ],
        },
    ],
    jaffa: [
        () => <Translate>We chose Israel as our second market. Jaffa not only fits the pillars of Nvak’s mission, but we feel passionate about the city, the diversity of the people, and the potential of the youth.</Translate>,
        {
            type: 'iframe',
            url: 'https://www.youtube.com/embed/5uvjZjwfk08',
        },
        () => <Translate>In August 2018, we launched Nvak in Israel. During our pilot program in Jerusalem, we selected 20 students from the 200 that applied from around the country via our online application process on our website. Their ages ranged from 16-28 and we recorded 18 demos of original music and brought over two Berklee College of Music faculty and one songwriter-producer from Los Angeles, BigTaste (Leroy Clampitt), who is best known for his work with Justin Bieber and Madison Beer.</Translate>,
        {
            type: 'iframe',
            url: 'https://www.youtube.com/embed/LlFh9GqC7q8',
        },
        () => <Translate>In our second year, we moved our programming to Jaffa in order to serve a more diverse group of musicians. We brought over teachers from Berklee College of Music and NYU's Clive Davis School who taught our unique, hyper-localized curriculum. Our 30 musicians wrote and recorded over 50 original songs in English, Arabic, and Hebrew. We chose 10 of the strongest songs and workshopped those with the songwriters and producers that we brought from the US and EU. Our A-list crew included Linnea Södahl (Zara Larsson), Noé, Gladius James and Mike Sabeth (Lizzo; Meghan Trainor).</Translate>,
        {
            type: 'iframe',
            url: 'https://www.youtube.com/embed/pijEh-yP73k',
        },
    ],
    lilongwe: [
        () => <Translate>In 2019, with the help of Madonna's nonprofit organization, Raising Malawi, and US State Department, Nvak launched in its third international market, Lilongwe, Malawi. Malawi has a rich musical history that stems from the numerous ethnic and cultural groups represented in the country and has a unique relationship to pop music.</Translate>,
        {
            type: 'iframe',
            url: 'https://www.youtube.com/embed/B1xjz78lqTA'
        },
        () => <Translate>After experiencing a period of censorship of the arts through the 1990's, Malawi has experienced a dramatic uptick in the arts, but despite rapid growth, women and young girls in Malawi are still drastically underrepresented in the arts and education sphere as a whole, and a lack of infrastructure keeps resources scarce.</Translate>,
        () => <Translate>Expanding to Lilongwe allowed Nvak to super serve young female artists and musicians in Malawi's capital and brought their unique voices and experiences to the global market.</Translate>,
    ],
    programming: [
        () => <Translate>We’ve updated our 2020 - 2021 programming to fit the current global climate.</Translate>,
        {
            type: 'h3',
            text: () => <Translate>Song Start: Globalizing Access to Music Education</Translate>,
        },
        () => (
            <>
                Nvak has partnered with Grammy-nominated / multi-platinum songwriter, <a href="https://www.instagram.com/alitamposi/" rel="noopener noreferrer" target="_blank">Ali Tamposi</a>, to build and distribute an online music education platform in partnership with Spotify called Song Start. Song Start creates free, accessible, high-quality contemporary music education through video and podcast content taught by a diverse selection of A-list creatives and executives who lead active careers in the music industry. Season 1 consists of holistic multi-media content which covers songwriting, music production, mental health, and music business, all designed to get students inspired and equipped to get started, and built on years of Nvak Foundation’s world-class curriculum development. Song Start aims to lower the barriers to entry that keep people of all ages from pursuing music and foster the next wave of music creators, entrepreneurs, and executives, with a goal of providing access to this content to 1 million users within the first year of launching. Season 1 talent includes TOKiMONSTA, Hayley Williams, John Legend, Andrew Watt, and more. Look for us on <a href="https://www.instagram.com/song.start/" rel="noopener noreferrer" target="_blank">Instagram</a>!
            </>
        ),
        {
            type: 'h3',
            text: () => <Translate>Lilongwe</Translate>,
        },
        () => (
            <>
                Our team in Malawi is carrying out the first year of Nvak’s year-round remote music program, innovating a mix of education, mentorship, and community support to serve 15 Malawian women. Artists access holistic programming each week on songwriting, production, music business, and mental health, and grow their support systems through online networking with a peer community of young artists in the Middle East, Africa, Eastern Europe, and the US. A-list songwriters, music industry professionals, and world-class university professors serve as remote mentors for each artist throughout the program. Nvak is working together with our valued partners at Reverb Gives, Focusrite, Hosa Tech, Shure, and more to provide recording equipment to each artist in Malawi, supporting them as they learn how to write, produce, and release original music from the safety of their own homes during the COVID-19 pandemic. Stay tuned for more information on music releases and event announcements!
            </>
        ),
    ],
    cairo: [
        {
            type: 'h3',
            text: () => 'MuSHEqa',
        },
        () => <Translate>In partnership with the U.S. Embassy Cairo and SheArts, <b>Nvak Foundation is headed to Egypt in Fall 2023!</b></Translate>,
        () => <Translate>MuSHEqa is a music education and career development program for Egyptian women & girls, running from November 2023 - January 2024 in Cairo, Egypt.</Translate>,
        () => <Translate><strong>Participating students will:</strong></Translate>,
        {
            type: 'ul',
            items: [
                () => 'Learn modern music business, production, and songwriting',
                () => 'Collaborate with award-winning hitmakers from the U.S. and MENA',
                () => 'Perform & release original music',
                () => 'Join a global community of writers, producers, and artists from around the world',
                () => 'Jumpstart their music careers',
            ],
        },
        () => <Translate><strong>How It Works:</strong></Translate>,
        {
            type: 'ul',
            items: [
                () => `Submit applications by October 20, 2023 *(More information below)*`,
                () => (
                    <>
                        <p>Attend three weekly masterclasses from November 1, 2023 - December 20, 2023.</p>
                        <p><small>*Wednesday evening classes are held virtually, and Friday & Saturday classes are held in-person at the American University in Cairo.*</small></p>
                    </>
                ),
                () => `Write and produce original music with global hitmakers`,
                () => `Perform at an end-of-program showcase`,
                () => `Release music as NFTs and on digital streaming platforms`,
            ],
        },
        {
            type: 'h2',
            text: () => 'How to Apply:',
        },
        () => <Translate>Now seeking 40 female artists, songwriters, and producers between the ages of 18-35 from around Egypt to participate in this life changing program. Applications are due by October 20, 2023 at 9:00pm PT, and the program begins November 1, 2023.</Translate>,
        {
            type: 'node',
            children: (
                <div className='tmp-items-line-holder'>
                    <a
                        className='button secondary'
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://814tnhg9bjc.typeform.com/to/WsPO6i7Z"
                    >
                        Apply Here in English
                    </a>
                    <a
                        className='button secondary'
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://814tnhg9bjc.typeform.com/to/oKOX3C02"
                    >
                        Apply Here in Arabic
                    </a>
                </div>
            ),
        },
        {
            type: 'h2',
            text: () => 'FAQ:',
        },
        () => <b>Do I need to live in Cairo to participate?</b>,
        {
            type: 'ul',
            items: [
                () => `No, we accept applications from around Egypt.`,
                () => `Transportation, lodging, and meals are covered for traveling students.`,
            ],
        },
        () => <b>Where are the masterclasses held?</b>,
        {
            type: 'ul',
            items: [
                () => `Wednesday evening classes are held online.`,
                () => `Friday and Saturday evening classes are held at the American University in Cairo.`,
            ],
        },
        () => <b>Do I need previous experience writing and producing original music?</b>,
        {
            type: 'ul',
            items: [
                () => `No, writing and production skills will be learned during the program. However, previous music experience is preferred.`,
            ],
        },
        () => <b>How many students will be accepted? Do I need to be a certain age to apply?</b>,
        {
            type: 'ul',
            items: [
                () => `40 female students between the ages of 18-35 will be accepted.`,
            ],
        },
        () => <b>Can I release the music I write during the program on digital streaming platforms?</b>,
        {
            type: 'ul',
            items: [
                () => `Yes!`,
            ],
        },
        () => <b>Do I need my own laptop or will they be provided?</b>,
        {
            type: 'ul',
            items: [
                () => `Laptops will be provided.`,
            ],
        },
        () => <b>Will I sign a record deal?</b>,
        {
            type: 'ul',
            items: [
                () => `Under the right circumstances, Nvak Collective will offer a development deal to a high-potential and dedicated participant.`,
                () => `Artists will release music NFTs with Nvak Collective at the end of the program.`,
            ],
        },
        {
            type: 'h2',
            text: () => 'Key Partners',
        },
        {
            type: 'node',
            children: (
                <div className='tmp-items-line-holder gap-2'>
                    {[[shearts, shearts2x], [usCairo, usCairo2x]].map(([item1, item2], index) => (
                        <picture key={index}>
                            <source
                                srcSet={`${item2} 2x, ${item1} 1x`}
                                type="image/jpg"
                            />
                            <img
                                src={item1}
                                alt=''
                            />
                        </picture>
                    ))}
                </div>
            ),
        },
        {
            type: 'h2',
            text: () => 'Why Egypt?',
        },
        () => <Translate><b>Egypt, with a population of over 100 million, boasts a thriving music scene teeming with creative talent.</b> The country serves as a major cultural and artistic hub, having achieved success locally and regionally in the MENA marketplace. Additionally, Egypt has the distinction of being MENA’s largest physical and digital music market, and the recent surge in digital releases in the region has attracted new audiences to the local music scene.</Translate>,
        () => <Translate>Despite their potential, Egyptian talent, especially female talent, has struggled to break out beyond MENA due to barriers such as an absence of local industry infrastructure (recording studios, record labels, venues, etc.) and limited career opportunities. Local music stakeholders have serviced the same generation of artists for the last twenty years, and there is little investment in the next wave of Egyptian artists.</Translate>,
        () => <Translate>Nvak Foundation, the U.S. Embassy Cairo, and SheArts are coming together to pool our resources, identify, and invest in the future of 40 high-potential female artists through a program with quality learning, networking, and professional development opportunities that jumpstart their careers and connect them to the global industry. By fostering a supportive environment that celebrates their culture and realizes their career goals, <b>we aim to break down barriers, amplify diverse voices, and create a music industry that truly reflects the rich talents and aspirations of female musicians in Egypt and beyond.</b></Translate>,
        ...[
            {
                type: 'h3',
                text: () => 'موشيقى',
            },
            () => <Translate><b>بالشراكة مع السفارة الأمريكية بالقاهرة ومهرجان هي الفنون، تُطلق مؤسسة Nvak - مشروعها الجديد في مصر خلال خريف عام 2023.</b></Translate>,
            () => <Translate>(موشيقى)</Translate>,
            () => <Translate>برنامج تعليم الموسيقى والتطوير الوظيفي للفنانات المصريات</Translate>,
            () => <Translate>في الفترة بين نوفمبر 2023 - فبراير 2024</Translate>,
            () => <Translate><strong>مميزات المشاركة ببرنامج موشيقى التدريبي</strong></Translate>,
            {
                type: 'ul',
                items: [
                    () => 'التعرف على عالم صناعة الموسيقى الحديثة، وكيفية كتابة وإنتاج الأغاني',
                    () => 'العمل مع فنانين حائزين على جوائز من الشرق الأوسط والعالم',
                    () => 'كتابة ونشر أغانيكِ الخاصة',
                    () => 'الانضمام لشبكة كبيرة من الفنانين وكُتاب الأغاني حول العالم',
                    () => 'انطلاق رحلتك الموسيقية الخاصة',
                ],
            },
            () => <Translate><strong>كيف يمكنني المشاركة بالبرنامج؟</strong></Translate>,
            {
                type: 'ul',
                items: [
                    () => `التقديم من خلال استمارة التسجيل المتاحة حتى يوم 20 أكتوبر`,
                    () => 'حضور جلسات التدريب التي ستبدأ من 1 نوفمبر وحتى 20 ديسمبر، ستكون الجلسات كل أربعاء (أونلاين)، وأيام الجمعة والسبت بحضور المُدربين في مركز التحرير الثقافي بالجامعة الأمريكية بالقاهرة',
                    () => `كتابة وإنتاج الأغاني مع متخصصين عالميين في مجال الموسيقى`,
                    () => `أداء الأعمال المُنتجة في ليلة العروض النهائية للمشروع`,
                    () => `نشر الأغاني الأصلية على منصات الاستماع المختلفة`,
                ],
            },
            {
                type: 'h2',
                text: () => 'كيفية التسجيل',
            },
            () => <Translate>نحن نبحث عن 40 فنانة مصرية (موسيقيات، مغنيات، منتجات موسيقى، وكاتبات أغاني) في الفئة العمرية بين 18 و35 سنة، للاشتراك في برنامج (موشيقى) التدريبي. يجب التسجيل قبل يوم 20 أكتوبر - الساعة 6 مساءً بتوقيت القاهرة، ويبدأ البرنامج يوم 1 نوفمبر 2023</Translate>,
            {
                type: 'node',
                children: (
                    <div className='tmp-items-line-holder'>
                        <a
                            className='button secondary'
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://814tnhg9bjc.typeform.com/to/oKOX3C02"
                        >
                            رابط استمارة التسجيل
                        </a>
                    </div>
                ),
            },
            {
                type: 'h2',
                text: () => 'الأسئلة المتكررة بخصوص المشروع',
            },
            () => <b>هل المشاركة للقاطنات بالقاهرة فقط؟</b>,
            {
                type: 'ul',
                items: [
                    () => `لا، يُمكن المشاركة من جميع المحافظات.`,
                    () => `يتكفل المشروع بمصروفات الإقامة والتنقل والوجبات خلال أيام الجلسات.`,
                ],
            },
            () => <b>أين ستُعقد جلسات التدريب؟</b>,
            {
                type: 'ul',
                items: [
                    () => ` مساء الأربعاء (أونلاين).`,
                    () => ` أيام الجمعة والسبت في الجامعة الأمريكية بالتحرير.`,
                ],
            },
            () => <b>هل يجب أن تكون لدي خبرة في كتابة الأغاني وإنتاج الموسيقى؟</b>,
            {
                type: 'ul',
                items: [
                    () => ` ليس بالضرورة، سيتم تعلم مهارات الإنتاج وكتابة الأغاني في التدريب، لكن يُفضل وجود خبرة سابقة.`,
                ],
            },
            () => <b>كم عدد المشاركات؟ وما هي الفئة العمرية المطلوبة؟</b>,
            {
                type: 'ul',
                items: [
                    () => `نحن نبحث عن 40 مشاركة بين 18 و35 سنة.`,
                ],
            },
            () => <b>هل يمكنني مشاركة الموسيقى المُنتجة خلال التدريب على منصات الاستماع المختلفة؟</b>,
            {
                type: 'ul',
                items: [
                    () => `نعم!`,
                ],
            },
            () => <b>هل أحتاج لإحضار جهاز اللاب توب الشخصي أم سيوفر التدريب أجهزة؟</b>,
            {
                type: 'ul',
                items: [
                    () => ` سنعمل على توفير أجهزة خلال الجلسات.`,
                ],
            },
            () => <b>هل سأقوم بالتوقيع مع شركة إنتاج؟</b>,
            {
                type: 'ul',
                items: [
                    () => `يحرص تجمع Nvak على توفير الفرص المناسبة للمشاركات المتفوقات.`,
                    () => `ستنشر المشاركات موسيقاهن الخاصة بالتعاون مع تجمع Nvak في نهاية البرنامج التدريبي.`,
                ],
            },
            {
                type: 'h2',
                text: () => 'شركاء المشروع',
            },
            {
                type: 'node',
                children: (
                    <div className='tmp-items-line-holder gap-2'>
                        {[[shearts, shearts2x], [usCairo, usCairo2x]].map(([item1, item2], index) => (
                            <picture key={index}>
                                <source
                                    srcSet={`${item2} 2x, ${item1} 1x`}
                                    type="image/jpg"
                                />
                                <img
                                    src={item1}
                                    alt=''
                                />
                            </picture>
                        ))}
                    </div>
                ),
            },
            {
                type: 'h2',
                text: () => 'مشروع (موشيقى) في مصر',
            },
            () => <Translate>تفتخر مصر، التي يبلغ عدد سكانها أكثر من 100 مليون نسمة، بمشهد موسيقي مزدهر بالمواهب الإبداعية، وهي تُعتبر مركزًا ثقافيًا وفنيًا هامًا في منطقة الشرق الأوسط وشمال أفريقيا. بالإضافة إلى ذلك، تتميز مصر بكونها أكبر سوق للموسيقى المادية والرقمية في منطقة الشرق الأوسط وشمال أفريقيا، وقد اجتذبت الزيادة الأخيرة في الإصدارات الرقمية بالمنطقة جماهير جديدة إلى المشهد الموسيقي المحلي.</Translate>,
            () => <Translate>تُكافح المواهب المصرية، وخاصة المواهب النسائية من أجل الخروج من الشرق الأوسط وشمال أفريقيا بسبب عوائق مثل غياب البنية التحتية للصناعة المحلية (ستوديوهات وشركات التسجيل، وما إلى ذلك) ومحدودية فرص العمل. نجد أن العاملين المحليين في مجال الموسيقى قد خدموا نفس الجيل من الفنانين على مدار العشرين عامًا الماضية، في مقابل القليل من الاستثمار في الموجة القادمة من الفنانين المصريين.</Translate>,
            () => <Translate>تتعاون مؤسسة Nvak والسفارة الأمريكية بالقاهرة ومهرجان (هي الفنون) لاكتشاف مستقبل 40 فنانة ذات إمكانات عالية، وذلك من خلال برنامج يوفر فرصًا للتعلم والتواصل والتواصل والتطوير المهني و ربطهم بالصناعة العالمية. ومن خلال تعزيز بيئة داعمة تحتفي بثقافتهن وتحقق أهدافهن المهنية.</Translate>,
            () => <b>نحن نهدف إلى كسر الحواجز، وتعزيز الأصوات المتنوعة، وإنشاء صناعة موسيقية تعكس المواهب والتطلعات الغنية للموسيقيات في مصر وخارجها.</b>,
        ],
    ],
};
