import React from 'react';

import './loader.scss';

const Loader = props => {
    const {
        show,
    } = props;

    return (
        <div className={`loader-holder ${show ? 'active' : ''}`}>
            <div className="loader-content">
                <div className="loader-element" />
            </div>
        </div>
    )
};

export default Loader;
