import { t } from '../../helpers/translate';

import yerevanImage from '../../containers/city/media/yerevan.jpg';
import jaffaImage from '../../containers/city/media/jerusalem.jpg';
import lilongweImage from '../../containers/city/media/lilongwe.jpg';

export const applyFormQuestions = [
    {
        tag: 'cf-robot-message',
        'cf-questions': t("Hello, my name is Tamar. I am Nvak's founder.")
    },
    {
        tag: 'cf-robot-message',
        'cf-questions': t("Thanks so much for your interest in Nvak! We're so excited to offer our songwriting and production program in your city this year.")
    },
    {
        tag: 'cf-robot-message',
        'cf-questions': t("P.S. You are chatting with my 🤖 Robot assistant. So please give exact answers.")
    },
    {
        tag: 'input',
        name: 'name',
        id: 'firstname',
        required: true,
        maxlength: 35,
        'cf-questions': t("First off, what's your first name?"),
    },
    {
        tag: 'input',
        name: 'LastName',
        required: true,
        'cf-questions': t("{firstname}, what is your last name?"),
    },
    {
        tag: 'input',
        type: 'number',
        name: 'Age',
        required: true,
        'cf-questions': t("How old are you?"),
    },
    {
        tag: 'input',
        name: 'Gender',
        required: true,
        'cf-questions': t("{firstname}, what is your prepared pronoun? (example: she/her, he/him, they/them)"),
    },
    {
        tag: 'input',
        type: 'email',
        name: 'email',
        required: true,
        maxlength: 35,
        'cf-questions': t("What is your email address?"),
    },
    {
        tag: 'input',
        type: 'tel',
        name: 'PhoneNumber',
        required: true,
        'cf-questions': t("{firstname}, what is your phone number? (with country code)"),
    },
    {
        tag: 'fieldset',
        required: true,
        'cf-questions': t("What city are you applying for?"),
        children: [
            {
                tag: "input",
                type: 'radio',
                name: 'AppliedTo',
                value: "Yerevan",
                "cf-image": yerevanImage,
                "cf-label": "Yerevan",
            },
            {
                tag: "input",
                type: 'radio',
                name: 'AppliedTo',
                value: "Jaffa",
                "cf-image": jaffaImage,
                "cf-label": "Jaffa",
            },
            {
                tag: "input",
                type: 'radio',
                name: 'AppliedTo',
                value: "Lilongwe",
                "cf-image": lilongweImage,
                "cf-label": "Lilongwe",
            },
        ]
    },
    {
        tag: 'fieldset',
        required: true,
        'cf-questions': t("Are you available for the entirety of the program?"),
        children: [
            {
                tag: "input",
                type: "radio",
                name: 'AvailableForProgram',
                value: "Yes",
                "cf-label": "Yes",
                'cf-conditional-AppliedTo': "Yerevan||Jaffa",
            },
            {
                tag: "input",
                type: "radio",
                name: 'AvailableForProgram',
                value: "No",
                "cf-label": "No",
                'cf-conditional-AppliedTo': "Yerevan||Jaffa",
            },
        ]
    },
    {
        tag: 'fieldset',
        required: true,
        'cf-questions': t("Nvak is not offering housing at the time of the program. Do you have a place to stay?"),
        children: [
            {
                tag: "input",
                type: "radio",
                name: 'HavePlace',
                value: "Yes",
                "cf-label": "Yes"
            },
            {
                tag: "input",
                type: "radio",
                name: 'HavePlace',
                value: "No",
                "cf-label": "No"
            },
        ]
    },
    {
        tag: 'input',
        name: 'LanguagesSpoken',
        required: true,
        'cf-questions': t("What languages do you know?"),
    },
    {
        tag: 'input',
        name: 'SourceToNvak',
        required: true,
        'cf-questions': t("How did you hear about us?"),
    },
    {
        tag: 'fieldset',
        required: true,
        multipleFor: 'AppliedAs',
        'cf-questions': t("What do you want to learn most in this program?"),
        children: [
            {
                tag: "input",
                type: "checkbox",
                name: 'AppliedAs',
                value: "Performance",
                "cf-label": "Performance"
            },
            {
                tag: "input",
                type: "checkbox",
                name: 'AppliedAs',
                value: "Songwriting",
                "cf-label": "Songwriting"
            },
            {
                tag: "input",
                type: "checkbox",
                name: 'AppliedAs',
                value: "Production",
                "cf-label": "Production"
            },
        ]
    },
    {
        tag: 'fieldset',
        required: true,
        multipleFor: 'WhoAreYou',
        'cf-questions': t("Do you play an instruments?"),
        children: [
            {
                tag: "input",
                type: "checkbox",
                name: 'WhoAreYou',
                value: "Instrumentalist",
                required: true,
                "cf-label": "Yes"
            },
            {
                tag: "input",
                type: "checkbox",
                name: 'WhoAreYou',
                value: "Vocalist",
                required: true,
                "cf-label": "I’m a vocalist"
            },
            {
                tag: "input",
                type: "checkbox",
                name: 'WhoAreYou',
                value: "Songwriter",
                required: true,
                "cf-label": "I’m a songwriter"
            },
        ]
    },
    {
        tag: 'input',
        name: 'Instruments',
        required: true,
        'cf-questions': t("That's perfect {firstname}! What instruments and How long have you been playing?"),
        'cf-conditional-WhoAreYou': 'Instrumentalist',
    },
    {
        tag: 'fieldset',
        required: true,
        'cf-questions': t("Do you use a computer to make music?"),
        children: [
            {
                tag: "input",
                type: "radio",
                name: 'ComputerProduction',
                value: "Yes",
                "cf-label": "Yes",
            },
            {
                tag: "input",
                type: "radio",
                name: 'ComputerProduction',
                value: "No",
                "cf-label": "No",
            },
        ]
    },
    {
        tag: 'input',
        name: 'Software',
        required: true,
        'cf-questions': t("What program do you use?"),
        'cf-conditional-ComputerProduction': 'Yes',
    },
    {
        tag: 'cf-robot-message',
        'cf-questions': t("Almost done.")
    },
    {
        tag: 'cf-robot-message',
        'cf-questions': t("So {firstname}, last thing but not the least.")
    },
    {
        tag: 'cf-robot-message',
        'cf-questions': t("Please record and submit a video essay via youtube, (it can be recorded on your cell phone or webcam) that explains your music experience, why you hope to participate in Nvak, and your goals and ambitions as a musician / artist / creator / writer.")
    },
    {
        tag: 'textarea',
        name: 'message',
        required: true,
        'cf-questions': t("Please submit a video of a performance, and explain your role in the creation of the piece performed: (were you the songwriter, the producer, the performer)? If you write original material, please submit a link or video to your original composition and explain a bit about your process to us."),
    },
];


export const underAgeQuestion = [
    {
        tag: "input",
        name: "ParentPhone",
        type: 'tel',
        required: true,
        "cf-questions": t("What is your parent or guardian's phone number?"),
    },
    {
        tag: "input",
        name: "ParentEmail",
        type: 'email',
        required: true,
        "cf-questions": t("What is their email address?"),
    },
    {
        tag: "input",
        name: "ParentName",
        required: true,
        "cf-questions": t("What is their full name?"),
    },
];
