import React from 'react';

import { Translate } from '../../helpers/translate';

import hp1PNG from './media/hp1.png';
import hp1WEBP from './media/hp1.webp';
//import hp2PNG from './media/hp2.png';
//import hp2WEBP from './media/hp2.webp';
import hp3PNG from './media/hp3.png';
import hp3WEBP from './media/hp3.webp';

export const galleryWidgetConfig = [
    {
        to: '/yerevan#gallery',
        text: <Translate>Yerevan's photos</Translate>,
        image: hp1PNG,
        imageWEBP: hp1WEBP,
    },
    /*{
        to: '/jaffa#gallery',
        text: <Translate>Jaffa's photos</Translate>,
        image: hp2PNG,
        imageWEBP: hp2WEBP,
    },*/
    {
        to: '/lilongwe#gallery',
        text: <Translate>Lilongwe's photos</Translate>,
        image: hp3PNG,
        imageWEBP: hp3WEBP,
    },
];
