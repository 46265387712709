import React, { useState } from 'react';

import LightBox from '../lightBox';

import { videosConfig, videoURLS } from './videosConfig';

import './videos.scss';

const Videos = () => {
    const [lightBoxOpened, setLightBoxOpened] = useState(false);
    const [openLightBoxAt, setOpenLightBoxAt] = useState(0);

    return (
        <>
            <div className="videos-block">
                {videosConfig.map((video, index) => (
                    <div
                        key={index}
                        className="videos-block-item"
                    >
                        <button
                            onClick={() => {setLightBoxOpened(val => !val); setOpenLightBoxAt(index)}}
                            className="icon-yube on-hover-affect"
                        >
                            <picture>
                                <source
                                    srcSet={video.imageWEBP}
                                    type="image/webp"
                                />
                                <source
                                    srcSet={video.image}
                                    type="image/jpeg"
                                />
                                <img
                                    src={video.image}
                                    alt={video.title}
                                />
                            </picture>
                        </button>
                    </div>
                ))}
            </div>
            <LightBox type="youtube" opened={lightBoxOpened} openAt={openLightBoxAt} sources={videoURLS} />
        </>
    )
};

export default Videos;
