import React from 'react';
import { NavLink } from 'react-router-dom';

import { galleryWidgetConfig } from './galleryWidgetConfig';

import './galleryWidget.scss';

const GalleryWidget = () => {
    return (
        <ul className="min-gallery-sec">
            {galleryWidgetConfig.map((item, index) => (
                <li key={index}>
                    <NavLink to={item.to} className="button blurry">
                        {item.text}
                    </NavLink>
                    <picture>
                        <source
                            srcSet={item.imageWEBP}
                            type="image/webp"
                        />
                        <source
                            srcSet={item.image}
                            type="image/png"
                        />
                        <img
                            src={item.image}
                            alt={item.text}
                        />
                    </picture>
                </li>
            ))}
        </ul>
    )
};

export default GalleryWidget;
