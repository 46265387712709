import React from 'react';

const ContentRenderer = props => {
    const {
        content,
        slug,
        breaks,
    } = props;

    return (content && slug) && (
        content[slug].map((item, index) => (
            <React.Fragment key={index}>
                {item.type ? (
                    ((
                        () => {
                            switch (item.type) {
                                case 'node':
                                    return item.children;
                                case 'h2':
                                    return <h2>{item.text()}</h2>;
                                case 'h3':
                                    return <h3>{item.text()}</h3>;
                                case 'iframe':
                                    return (
                                        <div className="inline-yt-player-w">
                                            <iframe
                                                width="560" height="315"
                                                src={item.url}
                                                frameBorder="0"
                                                allow="autoplay; encrypted-media"
                                                title={slug}
                                                allowFullScreen
                                            />
                                        </div>
                                    );
                                case 'ol':
                                    return (
                                        <ol>
                                            {item.items.map((listItem, index) => (
                                                <li key={index}>
                                                    {listItem()}
                                                </li>
                                            ))}
                                        </ol>
                                    );
                                case 'ul':
                                    return (
                                        <ul>
                                            {item.items.map((listItem, index) => (
                                                <li key={index}>
                                                    {listItem()}
                                                </li>
                                            ))}
                                        </ul>
                                    );
                                case 'quote':
                                    return (
                                        <div className="quote-section">
                                            <blockquote>
                                                {item.children}
                                            </blockquote>
                                            <p>
                                                {item.author}
                                            </p>
                                        </div>
                                    )
                                default:
                                    return null;
                            }
                        }
                    ))()
                ) : (
                    <>
                        <p>{item()}</p>
                        {breaks && (
                            <>
                                <br />
                                <br />
                            </>
                        )}
                    </>
                )}
            </React.Fragment>
        ))
    )
};

export default ContentRenderer;
