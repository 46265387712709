import React from 'react';
import classnames from 'classnames';

import Patrons from '../patrons';

import { Translate } from '../../helpers/translate';

import { advisersConfig } from './advisersConfig';

import './advisers.scss';

const patronsEnabled = false;

const Advisers = () => {
    return (
        <>
            <div className="our-partners-title our-adviser-title">
                <h2>
                    <Translate>Advisors</Translate>
                </h2>
            </div>
            <div className="our-adviser">
                <ul>
                    {advisersConfig.map((adviser, index) => (
                        <li key={index}>
                            <a
                                rel="noopener noreferrer"
                                href={adviser.url}
                                target="_blank"
                                className={classnames(
                                    `on-hover-affect`,
                                    {
                                        'pointer-events-none': adviser.url === '#',
                                    }
                                )}
                            >
                                <p>{adviser.name}</p>
                                <small>{adviser.company}</small>
                                <i />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            {patronsEnabled && (
                <>
                    <div className="our-partners-title our-adviser-title">
                        <h2>
                            <Translate>Patrons - Coming soon</Translate>
                        </h2>
                    </div>
                    <Patrons/>
                </>
            )}
        </>
    )
};

export default Advisers;
