import React from 'react';
import { NavLink } from 'react-router-dom';

import { Translate } from '../../helpers/translate';
import { homeAboutContent } from '../home/homeAboutContent';

import rosaImage from './media/rosa.jpg';
import taliaImage from './media/talia.jpg';
import storyboardsImage from './media/storyboards.jpg';

export const staticContent = {
    about: [
        {
            type: 'h2',
            text: () => <Translate>Our Mission</Translate>,
        },
        {
            type: 'iframe',
            url: 'https://www.youtube.com/embed/54hrAnPG1X0',
        },
        ...homeAboutContent,
        () => (
            <>
                {`Read more about how Nvak Foundation uplifts artists in need globally `}
                <NavLink to="/history-and-accomplishments">here</NavLink>
                !
            </>
        ),
        {
            type: 'iframe',
            url: 'https://www.youtube.com/embed/eGyFWln-WS8',
        },
    ],
    historyAccomplishments: [
        {
            type: 'node',
            children: (
                <div className='circles-holder'>
                    {[
                        [
                            '1 Billion+',
                            'streams on songs written during Nvak Foundation intensives',
                        ],
                        [
                            '10,000+',
                            'hours donated mentoring from veteran industry professionals',
                        ],
                        [
                            '5,000+',
                            'artists impacted from around the world',
                        ],
                        [
                            '50',
                            'artists have licensed original music through Viacom/MTV',
                        ],
                        [
                            '3',
                            'artists have signed major publishing deals with Sony/The Orchard',
                        ],
                        [
                            '2',
                            'artists have competed at the Eurovision Song Contest',
                        ],
                    ].map((item, index) => (
                        <div
                            key={index}
                            className='circle-holder'
                        >
                            <div className='circle-content'>
                                <div className='circle-t'>{item[0]}</div>
                                <div className='circle-p'>{item[1]}</div>
                            </div>
                        </div>
                    ))}
                </div>
            ),
        },
        () => <Translate>Armenian-American artist, Tamar Kaprelian, launched Nvak Foundation in 2016 with a mission to create safer pathways to inclusion in the global music industry for underrepresented artists.</Translate>,
        () => <Translate>What began as a summer intensive music program for youth in Armenia has transformed into a year-round supportive resource for developing artists, writers, musicians, and producers around the world. Since 2016, professionals have donated over 10,000 hours of education and mentorship and we’ve impacted over 5,000 young creatives, amplifying their voices locally and globally, and exposing global audiences to diverse cultures and contemporary voices:</Translate>,
        {
            type: 'quote',
            children: <Translate>I can now record my music thanks to production classes and recording equipment offered by Nvak. In Malawi, we used to say ‘there are no female producers.’ That is not true anymore!</Translate>,
            author: <Translate>Esther Chitheka Lewis, Nvak Malawi, 2021</Translate>,
        },
        () => <Translate>Creatives leave Nvak Foundation connected to a global peer network of emerging artists, writers, producers, entrepreneurs, A-list career mentors (Ali Tamposi, Alex Salibian, and more), and a new confidence in themselves and their futures.</Translate>,
        () => <Translate>With Nvak Foundation's support, creatives from under-served regions of the world have become the first women producers in their countries, have had original music licensed by MTV, and have performed for artists like Madonna, reshaping what's possible for the next generation of aspiring music professionals in their local communities.</Translate>,
        {
            type: 'h2',
            text: () => 'Artist Case Study',
        },
        {
            type: 'h3',
            text: () => 'Rosa Linn',
        },
        () => (
            <img
                alt="Rosa Linn"
                src={rosaImage}
            />
        ),
        () => <Translate>Armenian artist, producer, and songwriter, Rosa Linn, was discovered at a small village music festival by Nvak’s local A&Rs in 2019. We invited her to our Yerevan intensive that summer, where she <b>wrote global hit “SNAP”</b>. With Nvak’s support, Rosa has since:</Translate>,
        {
            type: 'ul',
            items: [
                () => <Translate>Represented Armenia at the <b>Eurovision Song Contest</b></Translate>,
                () => <Translate>Accumulated over 1 billion streams across platforms</Translate>,
                () => <Translate>Achieved Gold, Silver, and Platinum sales status in 13 countries</Translate>,
                () => <Translate>Gone #1 at Triple A U.S. Radio</Translate>,
                () => <Translate>Reached <b>#16</b> on Spotify Global Chart</Translate>,
                () => <Translate>Signed a major record deal with Columbia Records</Translate>,
                () => (
                    <Translate>
                        <a
                            target='_blank'
                            rel="noopener noreferrer"
                            href='https://www.youtube.com/watch?v=Ef8T0jUdPzw'
                        >
                            Performed on The Late Late Show with James Corden
                        </a>
                    </Translate>
                ),
                () => <Translate>Recent Collaborations: Diane Warren, Justin Tranter, Alex Hope, Dan Wilson, Laura Veltz</Translate>,
            ],
        },
        () => <Translate>For more information on our work with Rosa Linn, see her sizzle reel <a href='https://www.youtube.com/watch?v=VUUr7x0TA1U' target='_blank' rel="noopener noreferrer">here</a>.</Translate>
    ],
    unique: [
        {
            type: 'h2',
            text: () => <Translate>Modern Education</Translate>,
        },
        () => <Translate>Most music education programs focus on the preservation and interpretation of canonical works - meaning classical, folk, and jazz music. ​Nvak does not.​ Instead, we have created our own multi-tier, scalable curriculum that focuses on teaching what students ​actually​ listen to: Top 40 music in a variety of popular genres.</Translate>,
        () => <Translate>By focusing on styles of music that young people are already passionate about, Nvak is able to:</Translate>,
        {
            type: 'ul',
            items: [
                () => (
                    <>
                        <strong>
                            <Translate>Attract</Translate>
                        </strong>
                        {` `}
                        <Translate>more applicants from diverse communities, nationalities, and socioeconomic groups. </Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>Empower</Translate>
                        </strong>
                        {` `}
                        <Translate>students to make music that can engage their peers, reach wider communities, and garner music industry interest. students to make music that can engage their peers, reach wider communities, and garner music industry interest. </Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>Engage</Translate>
                        </strong>
                        {` `}
                        <Translate>students of all skill levels - from novices to advanced musicians. students of all skill levels - from novices to advanced musicians. </Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>Build</Translate>
                        </strong>
                        {` `}
                        <Translate>a multi-disciplinary program that teaches skills beyond music because we know that not every person who studies music goes on to become a musician.</Translate>
                    </>
                ),
            ],
        },
        {
            type: 'h2',
            text: () => <Translate>Why Popular Music?</Translate>,
        },
        () => <Translate>The world is already filled with hundreds of world-class programs offering classical music education.​ Almost every major city in the developed world and almost every country in the developing world has at least one classical music conservatory.</Translate>,
        () => <Translate>While the number of classical music conservatories has continued to grow, the audience hasn’t.​ In almost every country for which we have data, popular music genres significantly outpace sales of classical music. Training students in popular music allows them to speak to people of their own generation, regardless of their location or cultural values.</Translate>,
        () => <Translate>Classical music programs typically have a narrower focus on preserving tradition​, teaching mainly performance practice and instrumental proficiency. Popular music programs by necessity are multi-disciplinary, meaning that they incorporate a wealth of skills from outside of music - STEM skills such as audio engineering, graphic design, marketing, fashion, and business.</Translate>,
        {
            type: 'h2',
            text: () => <Translate>Milestones and Goals</Translate>,
        },
        {
            type: 'ul',
            items: [
                () => (
                    <>
                        <strong>
                            <Translate>Today:</Translate>
                        </strong>
                        {` `}
                        <Translate>10,000 hours of professional time committed (changing out 5,000).</Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>6 Months:</Translate>
                        </strong>
                        {` `}
                        <Translate>Successfully complete the final phase of the Malawi year-round remote program, supporting 15 young women as they learn to write, produce, release, and distribute their original music</Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>1 Year:</Translate>
                        </strong>
                        {` `}
                        <Translate>Uplift young music creators around the world with access to essential resources such as mental health services, vocal and dental care, entrepreneurial training, safe spaces to create, music equipment, and more through our Artist Care Program.</Translate>
                    </>
                ),
            ],
        },
    ],
    artistCareProgram: [
        {
            type: 'h2',
            text: () => <Translate>Problems Addressed:</Translate>,
        },
        () => <Translate>For over 7 years, Nvak Foundation has utilized music education and mentorship as the catalysts to helping young under-resourced and underrepresented creators overcome the most difficult challenges within their local communities. In doing this work, Nvak Foundation recognized the full scope of barriers keeping young artists everywhere from expressing their unique voices and reaching their full potential. While music is a powerful tool for creative expression, cultural storytelling, and economic empowerment globally, we’ve found the following barriers to entry exist:</Translate>,
        {
            type: 'ul',
            items: [
                () => (
                    <Translate>Contemporary music education is expensive, exclusive, inaccessible and often out of date, disproportionately affecting low-income and under-resourced communities.</Translate>
                ),
                () => (
                    <Translate>Emerging artists face systemic roadblocks beyond access to education and mentorship such as poverty, identity-based discrimination, and trauma, all of which prevent them from finding the support necessary to thrive and reach their full creative potential.</Translate>
                ),
            ],
        },
        () => <Translate>Access to critical resources like education, health services, music equipment, and supportive community are the links necessary to keep these creators on their path to success and contribute to a more inclusive music industry. Whether it be for an up-and-coming songwriter in the U.S. unable to afford medical care without health insurance, or a young female musician in Malawi with a dream of becoming a music producer but lacking access to the education and equipment to get there, artists everywhere need individualized aid as they develop.</Translate>,
        {
            type: 'h2',
            text: () => <Translate>Artist Care Program</Translate>,
        },
        () => <Translate>Nvak Foundation’s Artist Care Program is a free, 1 year intensive program that empowers young underrepresented creators to develop the artistic and entrepreneurial skills necessary to launch careers and make their voices heard in the music industry. Once program recipients are selected, Nvak’s dedicated team provides holistic support and services to address their diverse and individualized needs and goals, helping them to overcome obstacles that have historically hindered their ability to thrive. Throughout their program year, all Artist Care Program recipients have access to the following resources:</Translate>,
        {
            type: 'ol',
            items: [
                () => (
                    <>
                        <strong>Grants for Basic Needs</strong>
                        {` - `}
                        <Translate>Nvak Foundation’s team ensures young creators can meet their basic needs and access essential career resources like technology and digital access, allowing them to focus on their artistic and professional growth. We offer monthly stipends and donated services and equipment in the following areas:</Translate>
                        <ul>
                            <li>Medical Care</li>
                            <li>Dental Care</li>
                            <li>Vocal Care</li>
                            <li>Mental Health Care</li>
                            <li>Nutrition Counseling</li>
                            <li>Addiction Counseling</li>
                            <li>Digital Access, Technology & Equipment</li>
                            <li>Transportation, and more.</li>
                        </ul>
                    </>
                ),
                () => (
                    <>
                        <strong>Supportive Community</strong>
                        {` - `}
                        <Translate>We offer participants lifelong access to our robust network of aspiring creators from across the United States, the Middle East, Eastern Europe, and Africa, connecting artists through online channels and regular alumni programming. Not only does this offer unique opportunities for cross-cultural collaboration, but establishes ongoing peer support for young artists as they pursue their personal, professional, and creative goals.</Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>Artistic & Career Development</strong>
                        {` - `}
                        <Translate>Nvak helps build workforce readiness skills through year-round intensive training, professional mentorship, and real-world work experience:</Translate>
                        <ul>
                            <li>
                                Our innovative music education series, <a href="https://noteable.spotify.com/songstart" rel="noreferrer" target="_blank">SONG START</a>, co-created with Spotify’s Noteable, is a free resource for songwriting, music production, music business, and mental health education.
                            </li>
                            <li>
                                Yearlong, 1:1 mentorship with active industry professionals
                            </li>
                            <li>
                                Live workshops with A-list professionals in the industry (Ali Tamposi; Nija Charles; and more) and university professors (UCLA; NYU Clive Davis Institute; Syracuse Bandier Program).
                            </li>
                            <li>
                                Internship placements with global music industry stakeholders, empowering students to apply skills gained through the program
                            </li>
                            <li>
                                Financial management coaching
                            </li>
                            <li>
                                Stipends to cover additional training where necessary
                            </li>
                        </ul>
                    </>
                ),
            ],
        },
        {
            type: 'h2',
            text: () => <Translate>Impact Stories</Translate>,
        },
        () => <Translate>By the end of the program, young creators are empowered with the networks, skills, and resources essential to reaching their full creative and career potential. Hear more on our impact from our participants themselves:</Translate>,
        () => (
            <img
                alt="Talia Lahoud"
                src={taliaImage}
            />
        ),
        {
            type: 'quote',
            children: <Translate>I’m beyond grateful for the Artist Care Program. Through it, I have been able to access and learn from professionals across the world and equip myself with all the necessary tools and resources I need to become the artist that I strive to be emotionally, physically, and creatively.</Translate>,
            author: <Translate>Talia Lahoud (She/Her), Nvak Lebanon, 2020-2022</Translate>,
        },
        () => (
            <img
                alt="Storyboards"
                src={storyboardsImage}
            />
        ),
        {
            type: 'quote',
            children: <Translate>Being a part of any marginalized group is difficult, but being part of a marginalized group in the music industry is even more difficult. Having a way to have access to resources like therapy is so exciting because mental health is a journey. I'm so grateful to have a team of people, specifically Nvak, to be there to support me.</Translate>,
            author: <Translate>Storyboards (She/Her), Nvak U.S., 2022</Translate>,
        },
    ],
    funds: [
        {
            type: 'h2',
            text: () => <Translate>Immediate Goals</Translate>,
        },
        {
            type: 'ul',
            items: [
                () => (
                    <>
                        <strong>
                            <Translate>Offer:</Translate>
                        </strong>
                        {` `}
                        <Translate>Nvak participants year-rond in person and remote classes and workshops with skilled music educators and tastemakers from the United States and Europe. We have signed a Joint Venture publishing deal with Warner Chappell in order to sign a few students per year to receive their own publishing deals.</Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>Release:</Translate>
                        </strong>
                        {` `}
                        <Translate>Through our partnership with Warner and ADA, Nvak will regularly release student recordings for commercial distribution, and aggressively pursue film, television, and commercial music placement, radio play, and playlists. The royalties generated will go back to self-fund Nvak programming.</Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>Expand:</Translate>
                        </strong>
                        {` `}
                        <Translate>our program into new regions. Using feedback and data, identify where Nvak can have the greatest impact and build a presence in that region. It’s our goal to expand to a new market every year.</Translate>
                    </>
                ),
            ],
        },
        {
            type: 'h2',
            text: () => <Translate>Long Term Goals</Translate>,
        },
        {
            type: 'ul',
            items: [
                () => (
                    <>
                        <strong>
                            <Translate>Invest:</Translate>
                        </strong>
                        {` `}
                        <Translate>in the communities that we’re operating. Over time, find spaces that could use equipment and renovation, and use part of our funding to make a permanent impact in the local communities</Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>Develop:</Translate>
                        </strong>
                        {` `}
                        <Translate>self-sustaining creative communities for students to collaborate on new ideas, as well as develop a podcast to share those ideas to a larger audience</Translate>
                    </>
                ),
                () => (
                    <>
                        <strong>
                            <Translate>Launch:</Translate>
                        </strong>
                        {` `}
                        <Translate>the careers of multiple Nvak songwriters and artists yearly</Translate>
                    </>
                ),
            ],
        },
    ],
};
