import React, { useEffect, useRef, useState } from 'react';
import { ConversationalForm } from 'conversational-form';


import Loader from '../loader';
import Alert from '../alert';

import { applicationsSubmitURL, emails } from '../../constants/config';

import { t, Translate } from '../../helpers/translate';

import { applyFormConfig } from './applyFormConfig';
import { applyFormQuestions, underAgeQuestion } from './applyFormQuestions';

import './applyForm.scss';

const uniqueID = Math.random().toString(36).substr(2, 9).toUpperCase();

const ApplyForm = props => {
    const {
        onClose,
    } = props;

    const element = useRef(null);
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState({
        type: '',
        text: '',
        description: '',
        note: '',
    });

    useEffect(() => {
        if(element.current) {
            const multipleQuestions = applyFormQuestions.filter(question => question.multipleFor && question);
            const dynamicData = [
                {
                    name: 'ID',
                    value: uniqueID,
                },
            ];
            underAgeQuestion.forEach(obj => dynamicData.push({
                name: obj.name,
                value: '',
            }));

            const conversationalForm = ConversationalForm.startTheConversation({
                options: {
                    ...applyFormConfig,
                    flowStepCallback: (dto, success) => {
                        const dynamicDataMatch = dynamicData.find(obj => obj.name === dto.tag.name);
                        if (dynamicDataMatch) {
                            dynamicDataMatch.value = dto.tag.value;
                        }
                        if (dto.tag.name === 'Age' && parseInt(dto.tag.value) < 18) {
                            conversationalForm.addTags(underAgeQuestion)
                        };
                        return success();
                    },
                    submitCallback: () => {
                        setLoading(true);
                        const form = conversationalForm.getFormData();
                        dynamicData.forEach(obj => {
                            form.append(obj.name, obj.value);
                        });
                        multipleQuestions.forEach(question => {
                            const key = question.multipleFor;
                            const value = form.getAll(key).join(', ');
                            form.delete(key);
                            form.append(key, value);
                        });
                        fetch(applicationsSubmitURL, {
                            method: 'POST',
                            body: form
                        }).then(response => {
                            if (response.ok) {
                                setLoading(false);
                                setResponseData({
                                    type: 'success',
                                    text: t(`Thank you! I've received your application.`),
                                    description: (
                                        <>
                                            <Translate>If your videos are not ready, just email the links to</Translate>
                                            {` `}
                                            <a href={`mailto:${emails.songs}?subject=${uniqueID}: Application materials`}>
                                                {emails.songs}
                                            </a>
                                            {` `}
                                            <Translate>with subject</Translate>
                                            {` ${uniqueID} - Application materials`}
                                        </>
                                    ),
                                    note: t(`${uniqueID} - is your unique application id, so we can know who are you.`),
                                });
                            }
                        }).catch(error => {
                            setLoading(false);
                            setResponseData({
                                type: 'fail',
                                text: error.message,
                                description: '',
                                note: '',
                            });
                        });
                    }
                },
                tags: applyFormQuestions,
            });
            element.current.appendChild(conversationalForm.el);
        }
    }, [element]);

    return (
        <>
            <div
                className="apply-form-holder"
                ref={element}
            >
                <Loader show={loading} />
            </div>
            <Alert
                type={responseData.type}
                onClose={onClose}
                show={!!responseData.text}
                text={responseData.text}
                description={responseData.description}
                note={responseData.note}
            />
        </>
    )
};

export default ApplyForm;
