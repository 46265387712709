import React from 'react';

import { Translate, t } from '../../helpers/translate';

import './subscribe.scss';

const Subscribe = () => {
    return (
        <div id="mc_embed_signup">
            <form
                action="https://nvak.us19.list-manage.com/subscribe/post?u=2d4e7ffa28f9a3e40954416cb&amp;id=a9a3987920"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
            >
                <label
                    htmlFor="mce-EMAIL"
                    className="subscribe-title"
                >
                    <Translate>Subscribe to our newsletter</Translate>
                </label>
                <div id="mc_embed_signup_scroll">
                    <div className="mc-field-group">
                        <input
                            type="email"
                            name="EMAIL"
                            placeholder={t('Email Address')}
                            id="mce-EMAIL"
                        />
                    </div>
                    <button
                        type="submit"
                        className="button has-mobile-icon"
                        name="subscribe"
                        aria-label="Subscribe to Nvak's newsletter"
                        id="mc-embedded-subscribe"
                    >
                        <span className="show-desktop">
                            <Translate>Subscribe</Translate>
                        </span>
                        <span className="show-mobile icon-message"/>
                    </button>
                </div>
            </form>
        </div>
    )
};

export default Subscribe;
