import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import SocialLinks from '../../components/socialLinks';
import { Translate } from '../../helpers/translate';
import useMobile from '../../hooks/useMobile';

import './header.scss';
import {toggleClass} from '../../helpers';

const Header = props => {
    const {
        scrollElement,
        desktopScrollElement,
        setShowMenu,
    } = props;

    const [isHeaderSticky, setIsHeaderSticky] = useState(false);
    const headerRef = useRef(null);
    const mobile = useMobile();

    useEffect(() => {
        toggleClass(document.body, isHeaderSticky, 'sticky');
    }, [isHeaderSticky]);

    useEffect(() => {
        const watchForSticky = () => {
            if(headerRef.current && scrollElement.current) {
                const scrollElementScrollTop = scrollElement.current.scrollTop;
                const headerHeight = headerRef.current.clientHeight;

                setIsHeaderSticky(scrollElementScrollTop + headerHeight > headerHeight);
            }
        };

        const element = mobile ? window : desktopScrollElement.current;
        if(element) {
            element.addEventListener('scroll', watchForSticky);
        }
        return () => element?.removeEventListener('scroll', watchForSticky);
    }, [mobile, desktopScrollElement, scrollElement]);

    return (
        <header ref={headerRef}>
            <div className="guide">
                <ul className="header-content">
                    <li>
                        <SocialLinks/>
                    </li>
                    <li>
                        <NavLink
                            to='/foundation'
                            aria-label="Homepage"
                            className="icon-logo"
                        />
                    </li>
                    <li>
                        {/*<NavLink
                            to='#apply'
                            className="toggle-apply-form show-desktop pointer apply-as-link"
                        >
                            <Translate>Apply</Translate>
                        </NavLink>*/}
                        <a
                            href="https://give.classy.org/nvak"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="show-desktop button medium secondary temp-big"
                        >
                          <Translate>Donate</Translate>
                        </a>
                        <div className="menu-button" onClick={() => setShowMenu(val => !val)}>
                            <i/>
                            <i/>
                            <i/>
                        </div>
                    </li>
                </ul>
            </div>
        </header>
    )
};

export default Header;
