import aboutMobile from './media/aboutMobile.mp4';
import about from './media/about.mp4';
import funds from './media/funds.mp4';
import fundsMobile from './media/fundsMobile.mp4';
import homepage from './media/homepage.mp4';
import homepageMobile from './media/homepageMobile.mp4';
import unique from './media/unique.mp4';
import uniqueMobile from './media/uniqueMobile.mp4';

export const introConfig = {
    homepage: {
        web: homepage,
        mobile: homepageMobile,
    },
    about: {
        web: about,
        mobile: aboutMobile,
    },
    unique: {
        web: unique,
        mobile: uniqueMobile,
    },
    historyAccomplishments: {
        web: unique,
        mobile: uniqueMobile,
    },
    artistCareProgram: {
        web: funds,
        mobile: fundsMobile,
    },
};
