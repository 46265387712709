import React from 'react';

import { teamConfig } from './teamConfig';

import './team.scss';

const Team = () => {
    return (
        <section>
            <div className="guide">
                {teamConfig.map((member, index) => member.image && (
                    <ul
                        key={index}
                        className="team-member"
                    >
                        <li>
                            <figure style={{backgroundImage: `url(${member.image})`}} />
                        </li>
                        <li>
                            <h2>{member.name}</h2>
                            <h3>{member.position}</h3>
                            {member.text && (
                                <p>{member.text}</p>
                            )}
                        </li>
                    </ul>
                ))}
            </div>
        </section>
    )
};

export default Team;
