import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactFitText from 'isomorphic-react-fittext';

import { t, Translate } from '../../helpers/translate';
import useMobile from '../../hooks/useMobile';

import { routes } from '../../constants/routes';

import { introConfig } from './introConfig';

import './intro.scss';

import welcomeEn from './media/en.svg';

const Intro = props => {
    const {
        type,
        slug,
        scrollElement,
    } = props;

    const [pageTitle, setPageTitle] = useState('');

    const introRef = useRef(null);

    useEffect(() => {
        setPageTitle(routes.find(route => route?.slug === slug && route)?.name);
    }, [slug]);

    useEffect(() => {
        const element = document.body.classList;
        const className = 'not-regular-page';

        element.add(className);

        return () => element.remove(className);
    }, []);

    const mobile = useMobile();

    const scrollToSection = () => {
        if (introRef.current && scrollElement.current) {
            const sectionOffsetTop = introRef.current.nextElementSibling.offsetTop;
            const headerHeight = document.querySelectorAll('header')[0].clientHeight;

            scrollElement.current.scrollTo({
                top: sectionOffsetTop - headerHeight,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div
            className="intro-section black-background full-screen"
            ref={introRef}
        >
            <video
                loop
                muted
                autoPlay
                playsInline
                className={slug}
                datatype="video/mp4"
                src={mobile ? introConfig[slug].mobile : introConfig[slug].web}
            />
            {type === 'main' && (
                <div className="splash-guide">
                    <div className="guide">
                        <img
                            src={welcomeEn}
                            alt={t('Apply')}
                        />
                        <a
                            href="https://give.classy.org/nvak"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="button blurry shadow opacity"
                        >
                            <Translate>Donate</Translate>
                        </a>
                    </div>
                </div>
            )}
            <div className="splash-guide">
                <div className="guide">
                    {type === 'main' ? (
                        <>
                            <img
                                className="opacity"
                                src={welcomeEn}
                                alt={t('Apply')}
                            />
                            <a
                                href="https://give.classy.org/nvak"
                                rel="noopener noreferrer"
                                target="_blank"
                                className="button blurry shadow"
                            >
                                <Translate>Donate</Translate>
                            </a>
                        </>
                    ) : (
                        <ReactFitText
                            compressor={slug === 'historyAccomplishments' ? 0.9 : 0.6}
                            minFontSize={30}
                            maxFontSize={5000}
                        >
                            <h1 className={`intro-title ${slug}`}>
                                <Translate>{pageTitle?.replace(' (Coming Soon)', '')}</Translate>
                            </h1>
                        </ReactFitText>
                    )}
                </div>
            </div>
            <button
                aria-label={t('Scroll to section')}
                onClick={() => scrollToSection()}
                className="curve-button icon-curve pointer"
            />
        </div>
    )
};

Intro.defaultProps = {
    slug: 'homepage',
    type: 'main',
};

Intro.propTypes = {
    slug: PropTypes.string,
    type: PropTypes.oneOf(['main', 'static']),
};

export default Intro;
