import React from 'react';
import { NavLink } from 'react-router-dom';

import { isCurrentLink } from '../../helpers';
import { Translate } from '../../helpers/translate';
import { currentYear, emails } from '../../constants/config';
import { routes } from '../../constants/routes';

import './footer.scss';

const footerRoutes = routes.filter(route => {
    switch (route.slug) {
        case 'home':
        case 'artistCareProgram':
        case 'historyAccomplishments':
        case 'apply':
        case 'yerevan':
        case 'jaffa':
        case 'lilongwe':
        case 'team':
        case 'cairo':
            return true;
        default:
            return false;
    }
}).map(route => {
    switch (route.slug) {
        case 'home':
            return ({
                ...route,
                order: 1,
            })
        case 'artistCareProgram':
            return ({
                ...route,
                order: 5,
            })
        case 'historyAccomplishments':
            return ({
                ...route,
                order: 3,
            })
        case 'apply':
            return ({
                ...route,
                order: 7,
            })
        case 'yerevan':
            return ({
                ...route,
                order: 2,
            })
        case 'jaffa':
            return ({
                ...route,
                order: 4,
            })
        case 'lilongwe':
            return ({
                ...route,
                order: 6,
            })
        case 'team':
            return ({
                ...route,
                order: 8,
            })
        default:
            return route;
    }
}).sort((a, b) => a.order - b.order);

const Footer = () => {
    return (
        <footer>
            <div className="guide">
                <div className="footer-content">
                    <div className="footer-nav">
                        <h4>
                            <Translate>Discover</Translate>
                        </h4>
                        <ul>
                            {footerRoutes.map((route, index) => route?.slug !== 'destination' && (
                                <li key={index}>
                                    <NavLink
                                        exact
                                        to={route.to}
                                        isActive={() => isCurrentLink(route.to)}
                                    >
                                        {route.name}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="footer-side">
                        <h4>
                            {currentYear}
                            {` `}
                            <small>©</small>
                            {` `}
                            {process.env.REACT_APP_WEBSITE_NAME}
                        </h4>
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`mailto:${emails.main}`}
                        >
                            <strong>{emails.main}</strong>
                        </a>
                        <div className="license">
                            <Translate>Nvak, Inc. is a 501(c)(3) not-for-profit-organization. All contributors are tax deductible to the extent of the law. Our tax ID# is 82-5303346.</Translate>
                        </div>
                    </div>
                </div>
                <div className="footer-powered">
                    <Translate>
                        Powered by
                    </Translate>
                    {` `}
                    <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="http://clyne.design"
                    >
                        www.clyne.design
                    </a>
                </div>
            </div>
        </footer>
    )
};

export default Footer;
