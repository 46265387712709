export const galleryData = {
    yerevan: [
        {
            year: '2019',
            id: '2019-armenia',
            skeleton: 118,
        },
        {
            year: '2018',
            id: '2018-armenia',
            skeleton: 25,
        },
    ],
    jaffa: [
        {
            year: '2019',
            id: '2019-jerusalem',
            skeleton: 34,
        },
        {
            year: '2018',
            id: '2018-jerusalem',
            skeleton: 64,
        },
    ],
    lilongwe: [
        {
            year: '2019',
            id: '2019-malawi',
            skeleton: 28,
        },
    ],
};
