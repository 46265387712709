import React, { useState } from 'react';
import axios from 'axios';

import Loader from '../../components/loader';
import Alert from '../../components/alert';

import { t, Translate } from '../../helpers/translate';

import { emails } from '../../constants/config';

import './contactUs.scss';

const ContactUs = () => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = event => {
        event.preventDefault();
        const form = event.target;
        const data = new FormData(form);
        setLoading(true);
        setError('');
        axios({
            method: 'POST',
            url: '/assets/connection/contacts.php',
            responseType: 'text',
            data: data
        }).then(function (response) {
            setLoading(false);
            if (response.data === true) {
                form.reset();
                setSubmitted(true);
            } else {
                setError(response.data)
            }
        });
    };

    return (
        <section>
            <div className="guide">
                <div className="contact-us-form">
                    <h2>
                        <Translate>Contact Us</Translate>
                    </h2>
                    <p>
                        <Translate>Want to get in touch? We'd love to hear from you. Send us an email at <a href={`mailto:${emails.main}`}>{emails.main}</a></Translate>
                    </p>
                    <p>
                        <Translate>Or, simply fill out this form and someone from the team will get back to you.</Translate>
                    </p>
                    <form
                        onSubmit={handleSubmit}
                    >
                        <ul>
                            <li>
                                <label>
                                    <p className="req">
                                        <Translate>Name</Translate>
                                    </p>
                                    <input type="text" maxLength="35" name="firstName" required/>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <p className="req">
                                        <Translate>Last Name</Translate>
                                    </p>
                                    <input type="text" name="lastName" required/>
                                </label>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <label>
                                    <p className="req">
                                        <Translate>Email</Translate>
                                    </p>
                                    <input type="email" maxLength="35" name="email" required/>
                                </label>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <label>
                                    <p>
                                        <Translate>Recipient</Translate>
                                    </p>
                                    <div className="select-wrapper">
                                        <select name="recipient" defaultValue={1}>
                                            <option value={1}>
                                                {t("Nvak's Team")}
                                            </option>
                                            <option value={2}>
                                                {t("Technical Support")}
                                            </option>
                                        </select>
                                        <div className="icon-down"/>
                                    </div>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <p className="req">
                                        <Translate>Subject</Translate>
                                    </p>
                                    <input type="text" maxLength="45" name="subject" required/>
                                </label>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <label>
                                    <p className="req">
                                        <Translate>Message</Translate>
                                    </p>
                                    <textarea name="message" rows="8" required/>
                                </label>
                            </li>
                        </ul>
                        {error && (
                            <div className="errors-holder" dangerouslySetInnerHTML={{__html: error}} />
                        )}
                        <button className="button" type="submit">
                            <Translate>Send</Translate>
                        </button>
                        <Loader
                            show={loading}
                        />
                    </form>
                </div>
            </div>
            <Alert
                text={
                    <Translate>Thank you for your email, we'll contact you as soon as possible.</Translate>
                }
                show={submitted}
                onClose={() => setSubmitted(false)}
            />
        </section>
    );
};

export default ContactUs;
