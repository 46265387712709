export const socialLinksConfig = [
    {
        url: process.env.REACT_APP_INSTAGRAM,
        name: `Instagram`,
        type: `iram`,
    },
    {
        url: process.env.REACT_APP_YOUTUBE,
        name: `YouTube`,
        type: `yube`,
    },
    {
        url: process.env.REACT_APP_TWITTER,
        name: `Twitter`,
        type: `tter`,
    },
    {
        url: process.env.REACT_APP_FACEBOOK,
        name: `Facebook`,
        type: `fook`,
    },
];
