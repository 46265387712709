import jerusalemJPG from './media/jerusalem.jpg';
import lilongweJPG from './media/lilongwe.jpg';
import yerevanJPG from './media/yerevan.jpg';
import programmingJPG from './media/lilongweandla.jpg';
import cairo from './media/cairo.jpg';

export const cityConfig = {
    yerevan: {
        image: yerevanJPG,
    },
    jaffa: {
        image: jerusalemJPG,
    },
    lilongwe: {
        image: lilongweJPG,
    },
    programming: {
        image: programmingJPG,
    },
    cairo: {
        image: cairo,
    },
};
