import React from 'react';
import ReactFitText from 'isomorphic-react-fittext';

import { Translate } from '../../helpers/translate';
import { pressData } from './pressData';

import './press.scss';

const Press = () => {
    return (
        <section>
            <div className="guide">
                <div className="press-head">
                    <ReactFitText
                        compressor={0.1}
                        minFontSize={30}
                        maxFontSize={5000}
                    >
                        <div className="press-title">
                            <Translate>Recent News & Press</Translate>
                        </div>
                    </ReactFitText>
                </div>
                <ul className="press-data-holder">
                    {pressData.map((news, index) => {
                        const sharedProps = {
                            href: news.url,
                            target: "_blank",
                            rel: "noopener noreferrer",
                        };
                        return (
                            <li key={index}>
                                <a className="press-name"  {...sharedProps}>
                                    {news.name}
                                </a>
                                <div className="line" />
                                <a className="press-title"  {...sharedProps}>
                                    {news.title}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </section>
    )
};

export default Press;
