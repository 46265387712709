import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SocialLinks from '../../components/socialLinks';
import ContentRenderer from '../../components/contentRenderer';
import Gallery from '../../components/gallery';
import MediaGrid from '../../components/mediaGrid';

import { routes } from '../../constants/routes';
import { Translate } from '../../helpers/translate';

import { cityConfig } from './cityConfig';
import { cityContent } from './cityContent';

const City = props => {
    const {
        slug,
        country,
    } = props;

    const [pageTitle, setPageTitle] = useState('');
    const [mediaGridData, setMediaGridData] = useState(null);

    useEffect(() => {
        setPageTitle(routes.find(route => route?.slug === slug && route)?.name);
    }, [slug]);

    return (
        <>
            <section className={`our-mission static-city city-${slug}`}>
                <div className="guide">
                    <div className="image-card-wrapper">
                        <ul>
                            <li>
                                <div className="image-card-text">
                                    <h6>
                                        <Translate>Nvak</Translate>
                                        {` `}
                                        <span>
                                        <Translate>{country}</Translate>
                                    </span>
                                    </h6>
                                </div>
                            </li>
                            <li>
                                <figure style={{ backgroundImage: `url(${cityConfig[slug].image})` }} />
                            </li>
                        </ul>
                    </div>
                    <div className="sqs-block-content">
                        <h2>
                            <Translate>Nvak</Translate>
                            {` `}
                            {slug === 'cairo' ? country : pageTitle}
                        </h2>
                        <ContentRenderer
                            slug={slug}
                            content={cityContent}
                        />
                    </div>
                    <div className="inline-social">
                        <SocialLinks />
                    </div>
                </div>
                <Gallery slug={slug} setData={setMediaGridData} />
            </section>
            <MediaGrid data={mediaGridData} />
        </>
    );
};

City.propTypes = {
    slug: PropTypes.oneOf(['yerevan', 'jaffa', 'lilongwe', 'programming', 'cairo']),
};

export default City;
