import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import axios from 'axios';

import { t } from '../../helpers/translate';

import { galleryData } from './galleryData';

import './gallery.scss';

const Gallery = props => {
    const {
        slug,
        setData,
    } = props;

    const currentData = galleryData?.[slug];
    const [selectedYear, setSelectedYear] = useState(currentData?.[0]);

    useEffect(() => {
        let shouldCancel = false;
        if(selectedYear?.id) {
            const path = `${process.env.PUBLIC_URL}/assets/media/${selectedYear?.id}/`;
            (async () => {
                const response = await axios.get(
                    `${path}index.json`
                );
                if (!shouldCancel && response.data && response.data.length > 0) {
                    setData({
                        skeleton: selectedYear.skeleton,
                        images: response.data.map(image => path + image),
                    })
                }
            })();
        }

        return () => {
            shouldCancel = true
        }
    }, [selectedYear?.id]); // eslint-disable-line


    const title = t(`${slug}'s Photos`);

    return !!currentData && (
        <div className="gallery-head" id="gallery">
            <div className="gallery-title">{title}</div>
            <div className="gallery-tabs">
                {currentData.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => setSelectedYear(item)}
                        className={classnames({
                            'active': selectedYear.year === item.year,
                        })}
                    >
                        {item.year}
                    </button>
                ))}
            </div>
        </div>
    )
};

export default Gallery;
