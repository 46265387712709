import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import LightBox from '../lightBox';

import './mediaGrid.scss';

const MediaGrid = props => {
    const {
        data,
    } = props;

    const [lightBoxOpened, setLightBoxOpened] = useState(false);
    const [openLightBoxAt, setOpenLightBoxAt] = useState(0);

    return data && (
        <>
            <ul className="media-grid">
                {data.skeleton && (
                    data.images ? (
                        data.images.map((image, index) => (
                            <li key={index}>
                                <LazyLoadImage
                                    className="on-hover-affect lazy"
                                    alt={data.year}
                                    src={image}
                                    onClick={() => {setLightBoxOpened(val => !val); setOpenLightBoxAt(index)}}
                                />
                            </li>
                        ))
                    ) : (
                        Array(data.skeleton).fill('').map((_, index) => (
                            <li key={index} className="pointer-events-none" />
                        ))
                    )
                )}
            </ul>
            {(data && data.images) && (
                <LightBox
                    sources={data.images}
                    opened={lightBoxOpened}
                    openAt={openLightBoxAt}
                />
            )}
        </>
    )
};

export default MediaGrid;
