import React from 'react';
import { NavLink } from 'react-router-dom';

import SocialLinks from '../socialLinks';

import { t, Translate } from '../../helpers/translate';
import { isCurrentLink } from '../../helpers';

import { navigationRoutes } from '../../constants/routes';

const Navigation = () => {
    return (
        <div className="navigation-holder">
            <div className="guide">
                <nav>
                    <ul>
                        {navigationRoutes.map((route, index) => route?.slug !== 'destination' && (
                            route.children ? (
                                <li key={index}>
                                    <div className="dropdown-menu">
                                        <ul>
                                            {route.children.map(((childRoute, index) => (
                                                <li key={index}>
                                                    <NavLink
                                                        className="nav-item"
                                                        exact
                                                        to={childRoute.to}
                                                        title={childRoute.name}
                                                        isActive={() => isCurrentLink(route.to)}
                                                    >
                                                        {childRoute.name}
                                                    </NavLink>
                                                </li>
                                            )))}
                                        </ul>
                                    </div>
                                    <div className="nav-item" title={t(route.name)}>
                                        <p>
                                            <Translate>{route.name}</Translate>
                                        </p>
                                        <div className="icon-down"/>
                                    </div>
                                </li>
                            ) : (
                                <li key={index} className={route.className}>
                                    <NavLink
                                        className="nav-item"
                                        exact
                                        to={route.to}
                                        title={t(route.name)}
                                        isActive={() => isCurrentLink(route.to)}
                                    >
                                        <Translate>{route.name}</Translate>
                                    </NavLink>
                                </li>
                            )
                        ))}
                    </ul>
                </nav>
                <div className="mobile-header-content show-mobile">
                    <a
                        href="https://give.classy.org/nvak"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="button medium secondary">
                        <Translate>Donate</Translate>
                    </a>
                    <div className="mobile-header-footer">
                        <SocialLinks/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Navigation
