import React from 'react';

import Home from '../containers/home';
import Team from '../containers/team';
import ContactUs from '../containers/contactUs';
import Static from '../containers/static';
import City from '../containers/city';
import Press from '../containers/press';

import { Translate } from '../helpers/translate';
import { webSiteDescription, webSiteKeywords, webSiteTitle } from './config';

export const routes = [
    {
        name: process.env.REACT_APP_WEBSITE_SHORT_NAME,
        to: '/',
        slug: 'destination',
        component: customProps => <Home {...{...customProps, chooseDestination: true}} />,
        order: 0,
        meta: {
            title: webSiteTitle,
            description: webSiteDescription,
            keywords: webSiteKeywords,
        },
    },
    {
        name: process.env.REACT_APP_WEBSITE_SHORT_NAME,
        to: '/foundation',
        slug: 'home',
        component: customProps => <Home {...customProps} />,
        className: 'show-mobile',
        order: 1,
        meta: {
            title: webSiteTitle,
            description: webSiteDescription,
            keywords: webSiteKeywords,
        },
    },
    {
        name: 'About Us',
        to: '/about-us',
        slug: 'about',
        component: customProps => <Static slug="about" {...customProps} />,
        order: 2,
        group: [
            'none',
        ],
        meta: {
            title: 'About Us',
            description: 'Nvak creates opportunities where little to none exists: using music as a tool for underserved teens and young adults to find their voices and amplify their stories beyond their countries borders.',
            keywords: webSiteKeywords,
        },
    },
    {
        name: 'History & Accomplishments',
        to: '/history-and-accomplishments',
        slug: 'historyAccomplishments',
        component: customProps => <Static slug="historyAccomplishments" {...customProps} />,
        order: 1,
        group: [
            'mission',
        ],
        meta: {
            title: 'History & Accomplishments',
            description: 'Most music education programs focus on the preservation and interpretation of canonical works. ​Nvak does not.​ Instead, we have created our own multi-tier, scalable curriculum: Top 40 music in a variety of popular genres.',
            keywords: webSiteKeywords,
        },
    },
    /*{
        name: '2020 - 2021 Programming',
        to: '/2020-2021-programming',
        slug: 'programming',
        order: 2,
        component: customProps => (
            <City
                slug="programming"
                {...customProps}
                country={
                    <Translate>Los Angeles & Lilongwe</Translate>
                }
            />
        ),
        group: [
            'mission',
        ],
        meta: {
            title: '2021 Programming',
            description: `Nvak Foundation's roadmap for 2020 year, join today!`,
            keywords: webSiteKeywords,
        },
    },*/
    {
        name: 'Artist Care Program',
        to: '/artist-care-program',
        slug: 'artistCareProgram',
        component: customProps => <Static slug="artistCareProgram" {...customProps} />,
        order: 2,
        group: [
            'mission',
        ],
        meta: {
            title: 'Artist Care Program',
            description: 'For over 5 years, Nvak Foundation has utilized music education and mentorship as the catalysts to help under-resourced and underrepresented creatives overcome the most difficult challenges within their communities.',
            keywords: webSiteKeywords,
        },
    },
    {
        name: 'Recent News & Press',
        to: '/recent-news-press',
        slug: 'recent-news-press',
        component: customProps => <Press slug="recent-news-press" {...customProps} />,
        order: 4,
        group: [
            'mission',
        ],
        meta: {
            title: 'Recent News & Press',
            description: 'All the latest breaking news on Music Industry associated with Nvak Foundation.',
            keywords: webSiteKeywords,
        },
    },
    /*
    {
        name: 'Our Artists',
        to: '/our-artists',
        slug: 'ourArtists',
        order: 3,
        component: customProps => <OurArtists  {...customProps} />,
        meta: {
            title: 'Our Artists',
            description: `Meet Nvak's talented artists.`,
            keywords: webSiteKeywords,
        },
    },*/
    {
        name: 'Yerevan',
        to: '/yerevan',
        slug: 'yerevan',
        order: 4,
        component: customProps => (
            <City
                slug="yerevan"
                {...customProps}
                country={
                    <Translate>Armenia</Translate>
                }
            />
        ),
        group: [
            'cities',
        ],
        meta: {
            title: 'Yerevan',
            description: `All about Nvak's program in Yerevan, Armenia.`,
            keywords: webSiteKeywords,
        },
    },
    {
        name: 'Cairo',
        to: '/cairo',
        slug: 'cairo',
        order: 4,
        component: customProps => (
            <City
                slug="cairo"
                {...customProps}
                country={
                    <Translate>Egypt</Translate>
                }
            />
        ),
        group: [
            'cities',
        ],
        meta: {
            title: 'Cairo, Egypt',
            description: `All about Nvak's program in Cairo, Egypt.`,
            keywords: webSiteKeywords,
        },
    },
    /*{
        name: 'Jaffa',
        to: '/jaffa',
        slug: 'jaffa',
        order: 4,
        component: customProps => (
            <City
                slug="jaffa"
                {...customProps}
                country={
                    <Translate>Israel</Translate>
                }
            />
        ),
        group: [
            'cities',
        ],
        meta: {
            title: 'Jaffa',
            description: `All about Nvak's program in Jaffa, Israel.`,
            keywords: webSiteKeywords,
        },
    },*/
    {
        name: 'Lilongwe',
        to: '/lilongwe',
        slug: 'lilongwe',
        order: 4,
        component: customProps => (
            <City
                slug="lilongwe"
                {...customProps}
                country={
                    <Translate>Malawi</Translate>
                }
            />
        ),
        group: [
            'cities',
        ],
        meta: {
            title: 'Lilongwe',
            description: `All about Nvak's program in Lilongwe, Malawi.`,
            keywords: webSiteKeywords,
        },
    },
    {
        name: 'Team',
        to: '/team',
        slug: 'team',
        order: 5,
        component: customProps => <Team  {...customProps} />,
        meta: {
            title: 'Team',
            description: `Meet Nvak's talented team.`,
            keywords: webSiteKeywords,
        },
    },
    {
        name: 'Apply',
        to: '/foundation#apply',
        slug: 'apply',
        order: 6,
        meta: {
            title: 'Apply',
            description: webSiteDescription,
            keywords: webSiteKeywords,
        },
    },
    {
        name: 'Contact Us',
        to: '/contact-us',
        slug: 'contactUs',
        order: 7,
        component: customProps => <ContactUs  {...customProps} />,
        meta: {
            title: 'Contact Us',
            description: `Stay in touch. Visit our Contacts page to find answers to common questions. Don't hesitate to contact us if you can't find what you're looking for.`,
            keywords: webSiteKeywords,
        },
    },
];

export const navigationRoutes = [
    ...routes.filter(route => !route.group),
    {
        name: 'Our Mission',
        order: routes.find(route => route.group && route.group.indexOf('mission') !== -1).order,
        children: [
            ...routes.filter(route => route.group && route.group.indexOf('mission') !== -1)
        ]
    },
    {
        name: 'Cities',
        order: routes.find(route => route.group && route.group.indexOf('cities') !== -1).order,
        children: [
            ...routes.filter(route => route.group && route.group.indexOf('cities') !== -1)
        ]
    },
].sort((a, b) => a.order - b.order);
