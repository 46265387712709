import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import FsLightbox from 'fslightbox-react';

const LightBox = props => {
    const {
        sources,
        opened,
        type,
        openAt,
    } = props;

    const [lightboxController, setLightboxController] = useState({
        toggler: opened,
        slide: openAt + 1,
    });

    useEffect(() => {
        setLightboxController({
            toggler: opened,
            slide: openAt + 1
        });
    }, [opened, openAt]);

    return ReactDOM.createPortal(
        <FsLightbox
            type={type}
            sources={sources}
            key={sources}
            {...lightboxController}
        />,
        document.body
    )
};

LightBox.defaultProps = {
    sources: [],
    opened: false,
    openAt: 1,
    type: 'image',
};

LightBox.propTypes = {
    sources: PropTypes.arrayOf(
        PropTypes.string,
    ),
    type: PropTypes.oneOf(['image', 'video', 'youtube']),
    opened: PropTypes.bool,
    openAt: PropTypes.number,
};

export default LightBox;
