import { useEffect, useState } from 'react';

const useMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    function checkForMobile() {
        setIsMobile(document.documentElement.clientWidth < 569);
    }

    useEffect(() => {
        checkForMobile();

        window.addEventListener('resize', checkForMobile);

        return () => window.removeEventListener('resize', checkForMobile);
    }, []);

    return isMobile;
};

export default useMobile;
