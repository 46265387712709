import v2PNG from './media/v2.png';
import v2WEBP from './media/v2.webp';
import v3PNG from './media/v3.png';
import v3WEBP from './media/v3.webp';
import v4JPG from './media/v4.jpg';
import v4WEBP from './media/v4.webp';

export const videoURLS = [
    'https://www.youtube.com/watch?v=dELBYmLSPMo',
    'https://www.youtube.com/watch?v=R2k430ruXrU',
    'https://www.youtube.com/watch?v=GfTNIIzRmVU',
];

export const videosConfig = [
    {
        url: videoURLS[0],
        title: 'Թամար Կապրելիան «Թույն արի» (Լավ երեկո)',
        image: v4JPG,
        imageWEBP: v4WEBP,
    },
    {
        url: videoURLS[1],
        title: 'BUSKING ALL OVER YEREVAN | NVAK',
        image: v2PNG,
        imageWEBP: v2WEBP,
    },
    {
        url: videoURLS[2],
        title: 'Lusine Mardanyan - If You Don\'t Walk Me Home (Live) Depi Evratesil 2018',
        image: v3PNG,
        imageWEBP: v3WEBP,
    },
];
