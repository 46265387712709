import classNames from 'classnames';
import React from 'react';

import { Translate } from '../../helpers/translate';

import { partnersConfig } from './partnersConfig';

import './partners.scss';

const Partners = () => {
    return (
        <div className="out-partners-section">
            <div className="our-partners-title">
                <h2>
                    <Translate>In Partnership With</Translate>
                </h2>
            </div>
            <div className="our-partners-block">
                {partnersConfig.map((partner, index) => {
                    return (
                        <a
                            key={index}
                            rel="noopener noreferrer"
                            href={partner.url}
                            target="_blank"
                            className={classNames(
                                {
                                    'pointer-events-none': !partner.url,
                                }
                            )}
                        >
                            <picture>
                                <source
                                    srcSet={`${require(`./media/${index + 1}c-2x.png`).default} 2x, ${require(`./media/${index + 1}c.png`).default} 1x`}
                                    type="image/png"
                                />
                                <img
                                    src={require(`./media/${index + 1}c.png`).default}
                                    alt={partner.name}
                                />
                            </picture>
                        </a>
                    )
                })}
            </div>
        </div>
    )
};

export default Partners;
