import React from 'react';

import { t } from '../../helpers/translate';
import { socialLinksConfig } from './socialLinksConfig';

import './socialLinks.scss';


const sharedProps = {
    rel: 'noopener noreferrer',
    target: '_blank',
};

const SocialLinks = () => {
    return (
        <div className="social-items">
            {socialLinksConfig.map((social, index) => (
                <a
                    key={index}
                    href={social.url}
                    aria-label={t(`${process.env.REACT_APP_WEBSITE_SHORT_NAME} on ${social.name}`)}
                    className={`type-${social.type}`}
                    {...sharedProps}
                >
                    <span className={`icon-${social.type}`} />
                </a>
            ))}
        </div>
    )
};

export default SocialLinks;
