export const currentYear = new Date().getFullYear();

export const webSiteTitle = process.env.REACT_APP_FULL_TITLE;
export const webSiteDescription = process.env.REACT_APP_META_DESCRIPTION;
export const webSiteKeywords = process.env.REACT_APP_META_KEYWORDS;

export const applicationsSubmitURL = 'https://script.google.com/macros/s/AKfycbyK0SvbozkmmwzfMiEAu8vcnIhH0p0iKdSqeVP7y1UOlRJfPVVX/exec';

export const emails = {
    main: 'info@nvak.org',
    songs: 'songs@nvak.org',
    lilongweContact: 'Kim@nvak.org',
};
