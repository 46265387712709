import React, { useEffect, useRef, useState } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory, NavLink } from 'react-router-dom';

import { toggleClass } from '../../helpers';
import { routes } from '../../constants/routes';
import useMobile from '../../hooks/useMobile';

import Header from '../header';
import Footer from '../footer';
import Alert from '../../components/alert';

import Apply from '../../components/apply';
import Navigation from '../../components/navigation';

import { webSiteTitle } from '../../constants/config';

import './main.scss'

const Main = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [openApply, setOpenApply] = useState(false);
    const [openComingSoonApply, setOpenComingSoonApply] = useState(false);

    const scrollElement = useRef(null);
    const desktopScrollElement = useRef(null);
    const mobile = useMobile();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const pathname = window.location.pathname;
        const route = routes.find(route => route.to === pathname);
        const metaTags = document.querySelectorAll('.dynamic-helmet');
        const title = pathname === '/' ? route?.meta.title : `${route?.meta.title} ${process.env.REACT_APP_WEBSITE_TITLE_DIVIDER} ${webSiteTitle}`;
        const galleryElement = document.getElementById('gallery');
        let scrollTop = 0;

        document.title = title;
        metaTags.forEach(tag => {
            if(tag.hasAttribute('class')) {
                const dataType = tag.getAttribute('class').split(' ')[1];
                switch (dataType) {
                    case 'url':
                        tag.setAttribute('content', window.location.href);
                        break;
                    case 'url-link':
                        tag.setAttribute('href', window.location.href);
                        break;
                    case 'title':
                        tag.setAttribute('content', title);
                        break;
                    default:
                        tag.setAttribute('content', route.meta[dataType]);
                }
            }
        });

        setShowMenu(false);

        if (galleryElement && location.hash === '#gallery') {
            scrollTop = galleryElement.offsetTop - document.querySelectorAll('header')[0].clientHeight;
        }

        if (location.hash !== '#apply' && scrollElement.current) {
            scrollElement.current.scrollTo({
                top: scrollTop,
                behavior: 'smooth',
            });
        }

        setOpenApply(false);
        setOpenComingSoonApply(location.hash === '#apply');
    }, [location.pathname, location.hash, scrollElement]);

    useEffect(() => {
        toggleClass(document.body, showMenu, 'show-menu');
    }, [showMenu]);

    useEffect(() => {
        toggleClass(document.body, openApply, 'show-apply-form');
    }, [openApply]);

    useEffect(() => {
        scrollElement.current = mobile ? document.documentElement : desktopScrollElement.current;
    }, [mobile]);

    const customProps = {
        scrollElement,
        desktopScrollElement,
        setShowMenu,
        openApply,
        setOpenApply,
    };

    return (
        <>
            <div className="layer-3d-holder">
                <Header {...customProps} />
                <Navigation {...customProps} />
                <div className="all-holder" ref={desktopScrollElement}>
                    <main>
                        <Switch>
                            {routes.map((route, index) => route.component && (
                                <Route exact path={route.to} key={index}>
                                    {route.component(customProps)}
                                </Route>
                            ))}
                            <Redirect from='/apply' to='/foundation#apply' />
                            <Redirect to='/'/>
                        </Switch>
                    </main>
                    <Footer {...customProps} />
                </div>
            </div>
            <Apply {...customProps} />
            <Alert
                type='info'
                onClose={() => {
                    setOpenComingSoonApply(false);
                    history.replace(location.pathname);
                }}
                show={openComingSoonApply}
                text='Apply to Nvak'
                noButton
                description={(
                    <div className='apply-buttons-holder'>
                        <a
                            href='https://forms.gle/2hfPPUeLuRzVo2RM8'
                            target='_blank'
                            rel="noopener noreferrer"
                        >
                            Apply to the Artist Care Program
                        </a>
                        <NavLink to='/cairo'>
                            Apply to MuSHEqa*
                        </NavLink>
                    </div>
                )}
                note='*Music Education and Career Development Program for Egyptian Women Artists'
            />
        </>
    )
};

export default Main
