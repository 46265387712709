import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './breakingHero.scss';
import { NavLink } from 'react-router-dom';

import cairo from './media/cairo.jpg';

const BreakingHero = () => {
    return (
        <div className="latest-release-section">
            <div className="guide">
                <ul className="latest-release-holder">
                    <li>
                        <div className="lr-image-holder">
                            <LazyLoadImage
                                src={cairo}
                                alt='Nvak Egypt'
                                className="lazy"
                            />
                        </div>
                    </li>
                    <li>
                        <div className="lr-artist">
                            Nvak Egypt
                        </div>
                        <div className="lr-s-title">
                            Now recruiting <br/> artists to participate <br/> in a once-in-a-lifetime music program in Cairo, Egypt
                        </div>
                        <div className="lr-title">2023-2024</div>
                        <NavLink to='/cairo' className='button blurry'>Learn More</NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default BreakingHero;
