import React from "react";
import ReactDOM from 'react-dom';

import './alert.scss';

const Alert = props => {
    const {
        text,
        type,
        show,
        note,
        onClose,
        noButton,
        description,
    } = props;

    return show && ReactDOM.createPortal((
        <div
            className="alert-holder"
            onClick={onClose}
        >
            <div
                className="alert-content"
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <div className="success-message">
                    {type === 'success' && (
                        <div className="animate-success-icon icon success">
                            <div className="success-circular-line-left"/>
                            <span className="success-line-tip"/>
                            <span className="success-line-long"/>
                            <div className="success-ring"/>
                            <div className="success-fix"/>
                            <div className="success-circular-line-right"/>
                        </div>
                    )}
                    <p>{text}</p>
                    {description && (
                        <div className="t-description">{description}</div>
                    )}
                    {note && (
                        <p className="t-note">{note}</p>
                    )}
                </div>
                {!noButton && (
                    <div className="alert-action-holder">
                        <button className="button" onClick={onClose}>Okay</button>
                    </div>
                )}
            </div>
        </div>
    ), document.body)
};

Alert.defaultProps = {
    type: 'success',
};

export default Alert;
