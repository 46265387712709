import React from 'react';
import Main from './layout/main';
import { BrowserRouter } from 'react-router-dom';

import './assets/scss/index.scss';

function App() {
    return (
        <BrowserRouter>
            <Main/>
        </BrowserRouter>
    );
}

export default App;
