import React from 'react';
import Intro from '../../components/intro';
import SocialLinks from '../../components/socialLinks';
import {staticContent} from './staticContent';
import ContentRenderer from '../../components/contentRenderer';

const Static = props => {
    const {
        slug,
        scrollElement,
    } = props;

    return (
        <>
            <Intro
                scrollElement={scrollElement}
                type="static"
                slug={slug}
            />
            <section className="our-mission static-second">
                <div className="guide">
                    <div className="sqs-block-content">
                        <ContentRenderer
                            slug={slug}
                            content={staticContent}
                        />
                    </div>
                    <div className="inline-social">
                        <SocialLinks />
                    </div>
                </div>
            </section>
        </>
    )
};

export default Static;
