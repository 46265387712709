import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

import './index.scss';

import { ReactComponent as Collective } from './media/collective.svg';
import { ReactComponent as Foundation } from './media/foundation.svg';
import { ReactComponent as Logo } from './media/logo.svg';
import { ReactComponent as SongStart } from './media/songstart.svg';
import { ReactComponent as Ventures } from './media/ventures.svg';


const ChooseDestination = () => {
    const [active, setActive] = useState(false);

    return ReactDOM.createPortal((
        <div className='choose-destination-holder'>
            <div className='cd-content'>
                <Logo/>
                <ul className='cs-content-list'>
                    <li>
                        <NavLink to='/foundation'>
                            <Foundation/>
                        </NavLink>
                    </li>
                    <li>
                        <a
                            href='https://www.nvakcollective.com'
                        >
                            <Collective/>
                        </a>
                    </li>
                    <li>
                        <div
                            className='hover-parent'
                            onClick={() => setActive(val => !val)}
                        >
                            <Ventures/>
                            <div
                                className={classnames(
                                    `icon-drop`,
                                    {
                                        active,
                                    }
                                )}
                            />
                        </div>
                        <div
                            className={classnames(
                                `ventures-holder`,
                                {
                                    active,
                                }
                            )}
                        >
                            <a href='https://www.songstart.org'>
                                <SongStart/>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    ), document.body);
};

export default ChooseDestination;
