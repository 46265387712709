export const pressData = [
    {
        name: 'Variety',
        title: `Songwriters Ali Tamposi and Tamar Kaprelian to Launch Music Education Series With Spotify (EXCLUSIVE)`,
        url: 'https://variety.com/2021/music/news/ali-tamposi-tamar-kaprelian-spotify-song-start-music-education-series-1234909758/',
    },
    {
        name: 'Billboard',
        title: `Nvak Foundation Is Building a Bridge to Global Musicians, Pandemic Or Not`,
        url: 'https://www.billboard.com/articles/business/9434852/nvak-foundation-building-bridge-global-musicians-pandemic',
    },
    {
        name: 'Fast Company',
        title: `The 10 most innovative music companies of 2020`,
        url: 'https://www.fastcompany.com/90457855/music-most-innovative-companies-2020',
    },
    {
        name: 'MTV',
        title: `Music From Siesta Key: Season 3, Episode 12`,
        url: 'http://soundtrack.mtv.com/post/music-from-siesta-key-season-3-episode-12/',
    },
    {
        name: 'MTV',
        title: `Love The Way You Feel is on "Ex On The Beach"`,
        url: 'http://soundtrack.mtv.com/post/music-from-ex-on-the-beach-season-3-episode-7/',
    },
    {
        name: 'Billboard',
        title: "Warner Music's ADA Signs Global Distribution Deal with Music Education Non-Profit Nvak",
        url: 'https://www.billboard.com/articles/business/8528256/warner-music-ada-nvak-global-distribution-deal-music-education-non-profit',
    },
    {
        name: 'The Fader',
        title: "Listen to a new single from Armenian pop singer Brunette",
        url: 'https://www.thefader.com/2019/08/22/love-the-way-you-feel-nvak-brunette-new-single-premiere',
    },
    {
        name: 'Now This',
        title: "Tamar Kaprelian Starts Music Program Nvak to Empower Young Women in Armenia",
        url: 'https://nowthisnews.com/videos/news/tamar-kaprelian-starts-music-program-nvak-to-empower-young-women',
    },
    {
        name: 'Ones To Watch',
        title: 'Armenian Pop Singer Brunette Releases "Love the Way You Feel" in Support of Non-Profit Nvak',
        url: 'https://www.onestowatch.com/blog/armenian-pop-singer-brunette-releases-love-the',
    },
    {
        name: 'Wiwibloggs',
        title: "Tamar Kaprelian’s Nvak Foundation featured on NowThis News following international expansion",
        url: 'https://wiwibloggs.com/2019/08/21/tamar-kaprelians-nvak-foundation-featured-on-nowthis-news-following-international-expansion/241659/',
    },
];
