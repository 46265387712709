import React from 'react';
import { NavLink } from 'react-router-dom';

import Partners from '../../components/partners';
import Advisers from '../../components/advisers';
import Videos from '../../components/videos';
import GalleryWidget from '../../components/galleryWidget';
import Subscribe from '../../components/subscribe';
import Intro from '../../components/intro';
import BreakingHero from '../../components/breakingHero';
import ChooseDestination from '../../components/chooseDestination';

import { Translate } from '../../helpers/translate';
import { homeAboutContent } from './homeAboutContent';

const Home = props => {
    const {
        scrollElement,
        chooseDestination,
    } = props;

    return chooseDestination ? (
        <ChooseDestination />
    ) : (
        <>
            <Intro scrollElement={scrollElement} />
            <section className="our-mission">
                <div className="guide">
                    <div className="sqs-block-content">
                        <h1>
                            <Translate>Our Mission</Translate>
                        </h1>
                        {homeAboutContent.map((item, index) => (
                            <p key={index}>
                                {item()}
                            </p>
                        ))}
                        <NavLink to="/about-us" className="button">
                            <Translate>More About Us</Translate>
                        </NavLink>
                    </div>
                </div>
            </section>
            <BreakingHero />
            <section>
                <div className="guide">
                    <Partners />
                    <Advisers />
                </div>
            </section>
            <Videos />
            <section className="quote-section">
                <div className="guide">
                    <blockquote>
                        <Translate>If you want to know what Nvak is, I will just describe it this way: It is a once in a
                            lifetime opportunity.</Translate>
                    </blockquote>
                    <p>
                        <Translate>- Ovsanna / Nvak Class of 2016</Translate>
                    </p>
                </div>
                <Subscribe />
                <GalleryWidget />
            </section>
        </>
    );
};

export default Home;
