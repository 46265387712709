import React from 'react';
import { useHistory } from "react-router-dom";

import ApplyForm from '../applyForm';

import { Translate } from '../../helpers/translate';
import { currentYear } from '../../constants/config';

import './apply.scss';

const Apply = props => {
    const {
        openApply,
        setOpenApply,
    } = props;

    const history = useHistory();

    const handleClose = () => {
        setOpenApply(false);
        history.replace({
            ...history.location,
            hash: '',
        })
    };

    return (
        <div className="apply-form-wrapper">
            <h2>
                <Translate>Nvak</Translate>
                {` `}
                {currentYear}
            </h2>
            <div
                className="toggle-apply-form"
                onClick={() => handleClose()}
            />
            {openApply && (
                <ApplyForm
                    onClose={() => handleClose()}
                />
            )}
        </div>
    )
};

export default Apply;
